import React, { useEffect, useRef } from 'react';
import SEO from "../common/SEO";
import HeaderTwo from '../common/header/HeaderTwo';
import FooterTwo from '../common/footer/FooterTwo';


import {Typed} from 'react-typed';
import ServiceFour from '../elements/service/ServiceFour';
import AdvanceTabOne from '../elements/advancetab/AdvanceTabOne';

import Separator from "../elements/separator/Separator";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Copyright from '../common/footer/Copyright';

/**
 * Page "Nos Missions" présentant l'engagement de Wealthtech Innovations dans le développement
 * de l'économie numérique à travers l'innovation blockchain et les cryptoactifs.
 * 
 * Cette page détaille les différentes missions de Wealthtech Innovations, mettant en lumière
 * l'importance de l'adoption de la technologie blockchain pour le futur économique et numérique.
 * Les visiteurs peuvent y découvrir l'impact potentiel de ces technologies et la vision de
 * l'entreprise pour un futur numérique inclusif et sécurisé.
 *
 * @returns {JSX.Element} Le composant React pour la page "Nos Missions".
 */
const NosMissions = () => {
    // Variable de l'url du frontend
    const API_URL_FRONTEND =process.env.REACT_APP_API_URL_FRONTEND;
    const typedTarget = useRef(null);

    useEffect(() => {
        const options = {
            strings: ["Wealthtech.", "Innovations."],
            typeSpeed: 80,
            backSpeed: 5,
            backDelay: 1000,
            loop: true,
        };

        const typed = new Typed(typedTarget.current, options);

        return () => {
            typed.destroy();
        };
    }, []);

    return (
        <>
            <SEO
                title="Nos Missions - Contribuer au Développement de l'Économie Numérique avec la Blockchain"
                description="Wealthtech Innovations s'engage à promouvoir le développement de l'économie numérique par l'innovation blockchain. Découvrez nos missions visant à équiper entreprises et institutions avec des solutions blockchain et cryptoactifs adaptées."
                canonicalUrl={`https://www.wealthtechinnovations.com/nos-missions`}
                ogTitle="Découvrez les Missions de Wealthtech Innovations pour l'Économie Numérique"
                ogDescription="Chez Wealthtech Innovations, nous sommes déterminés à utiliser la blockchain et les cryptoactifs pour transformer l'économie numérique. Apprenez-en plus sur nos missions et notre engagement envers l'innovation."
                ogUrl={`https://www.wealthtechinnovations.com/nos-missions`}
            />

            <main className="page-wrapper">
                
                <HeaderTwo btnStyle="btn-small round btn-icon" HeaderSTyle="header-not-transparent" />


                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 bg-transparent variation-2 my-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 order-2 order-lg-1 mt_md--40 mt_sm--40">
                                <div className="inner text-start">
                                    <h1 className="title theme-gradient display-two">Les missions <br /> De {" "}
                                        <span ref={typedTarget}></span>

                                    </h1>
                                    <p className="description">Notre mission est de contribuer activement au développement de l’économie numérique.</p>
                                    
                                </div>
                            </div>
                            <div className="col-lg-4 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img src="./images/wti/new/missions/missionBg-1.jpg" alt="Banner Images" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />

                {/* Start Service Area  */}
                <div className="rn-service-area height-750">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Tout ce qui concerne notre mission"
                                    title = "Missions."
                                    description = "Notre mission est de contribuer activement au développement de l’économie numérique en apportant aux entreprises, aux institutions et aux organismes, des solutions concrètes qui leur permettront de comprendre les enjeux et de saisir les opportunités liées au changement de paradigme induit par la blockchain et les cryptoactifs."
                                />
                            </div>
                        </div>
                        <ServiceFour 
                            serviceStyle = "service__style--1 icon-circle-style with-working-process"
                            textAlign = "text-center"
                            />
                    </div>
                </div>
                {/* End Service Area  */}


                <Separator />
                {/* Start Elements Area  */}
                <div className="rwt-advance-tab-area height-90">
                    <div className="container">
                        <div className="row mb--40">
                            <div className="col-lg-12">
                                <SectionTitle
                                    textAlign = "text-center"
                                    radiusRounded = ""
                                    subtitle = "Tout ce qui concerne nos convictions."
                                    title = "Nos convictions."
                                    description = "À l'aune de la révolution numérique impulsée par le développement et l’implémentation des nouvelles technologies, nous sommes convaincus que les actifs numériques, le WEB3 et les blockchains apportent des solutions durables qui répondent à plusieurs grands enjeux actuels."
                                />
                            </div>
                        </div>
                        <AdvanceTabOne />
                    </div>
                </div>
                {/* End Elements Area  */}

                <Separator />


                <FooterTwo />
                <Copyright />


            </main>
        </>
    )
}

export default NosMissions;
