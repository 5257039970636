import React, { useEffect, useRef } from 'react';
import {Typed} from 'react-typed';
import ScrollAnimation from 'react-animate-on-scroll';
import SEO from "../common/SEO";
import HeaderTwo from '../common/header/HeaderTwo';
import {Link} from "react-router-dom";
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import Separator from "../elements/separator/Separator";

// Pour le Carousel slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Carousel } from 'bootstrap';

/**
 * Liste des Crypto-actifs avec des détails pour chacun.
 *
 * @type {Object[]}
 */
const ActifsList = [
    { id: 1, img: '/images/wti/new/actifs-blockchain/actif-1.jpg', title: 'Liquidity provider', description: "Nous assurons la liquidité de vos actifs numériques en devises FIAT"},
    { id: 1, img: '/images/wti/new/actifs-blockchain/actif-2.jpg', title: 'Initiation : Crypto-actifs', description: "Nous proposons un programme d’initiation aux cryptos actifs au cours duquel vous saurez comment détenir des actifs numériques, comment les échanger et comment analyser un actif numérique"},
    { id: 1, img: '/images/wti/new/actifs-blockchain/actif-3.jpg', title: 'Création d’actifs numériques', description: "Nous vous accompagnons dans votre projet de création de jetons fongibles « cryptomonnaies » et de jetons non fongibles « NFT ». De l’idée à la conception des jetons, à la constitution des galeries d’art numérique. Nous vous aidons dans le choix de la plateforme de développement et dans la stratégie de communication. De plus, nous prenons en charge le lancement des processus ICO, IEO, FTO, INO."},
]
   

/**
 * Liste des actifs qui s'affichent quand on est sur le service crypto-actif.
 *
 * @type {Object[]}
 */
 const ActifList = [
    {
        id: 1,
        image: '/images/wti/actif/actif-1.png',
    },
    {
        id: 2,
        image: '/images/wti/actif/actif-2.png',
    },
    
    {
        id: 3,
        image: '/images/wti/actif/actif-3.png',
    },

    {
        id: 4,
        image: '/images/wti/actif/actif-4.png',
    },

    {
        id: 5,
        image: '/images/wti/actif/actif-5.png',
    },

    {
        id: 6,
        image: '/images/wti/actif/actif-6.png',
    },

    {
        id: 7,
        image: '/images/wti/actif/actif-7.png',
    },

    {
        id: 8,
        image: '/images/wti/actif/actif-8.png',
    },
];


/**
 * Page des détails du Crypto-actifs.
 *
 * @component
 * @returns {JSX.Element} Composant React
 */
const CryptoActifs = () => {
    // Variable de l'url du frontend
    const API_URL_FRONTEND =process.env.REACT_APP_API_URL_FRONTEND;

    const typedTarget = useRef(null);

   
    /**
     * useEffect qui fait défiler les mots
     *
     * @type {Object[]}
     */
    useEffect(() => {
        const options = {
            
            strings: ['Liquidity provider', 'Initiation : Crypto-actifs', 'Création d’actifs numériques',],
            typeSpeed: 80,
            backSpeed: 5,
            backDelay: 1000,
            loop: true,
        };

        const typed = new Typed(typedTarget.current, options);

        return () => {
            typed.destroy();
        };
    }, []);

    // Paramètres pour le carrousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: "linear"
    };


    
    return (
        <>
            <SEO
                title="Crypto-actifs : Liquidité, Initiation et Création de Jetons - Wealthtech Innovations"
                description="Découvrez nos services en crypto-actifs chez Wealthtech Innovations : de la fourniture de liquidité pour vos actifs numériques, à des programmes d'initiation sur les cryptomonnaies, jusqu'à l'accompagnement dans la création de jetons et d’art numérique. Transformez votre approche des actifs numériques avec notre expertise."
                canonicalUrl={`https://www.wealthtechinnovations.com/crypto-actifs`}
                ogTitle="Explorez les Services en Crypto-actifs avec Wealthtech Innovations"
                ogDescription="Rejoignez Wealthtech Innovations pour explorer les opportunités dans le monde des crypto-actifs. Que vous cherchiez à comprendre les cryptomonnaies, à créer des jetons ou à optimiser la liquidité de vos actifs numériques, nous avons les solutions."
                ogUrl={`https://www.wealthtechinnovations.com/crypto-actifs`}
            />

            <main className="page-wrapper">
                <HeaderTwo btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 variation-default height-650 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/wti/new/actifs-blockchain/actifBg-1.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center py-5">
                                    <h2 className="title display-one theme-gradient color-white">Crypto-actifs<br/>
                                    <span ref={typedTarget}></span>
                                    </h2>
                                    
                                    {/* <p className="description">Nous guidons l'exploration des technologies blockchain, proposant des solutions pour mieux saisir les enjeux des actifs numériques.</p> */}
                                    <div className="button-group">
                                        <Link className="btn-default btn-medium round btn-icon" to="/nos-offres">Nos offres</Link>
                                        {/* <Link className="btn-default btn-medium btn-border round btn-icon" to="/contact">Contactez-nous <i className="icon"></i></Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />
                {/* <AboutTwo data={data[0]}/> */}

                {/* LES CONFERENCES ICI */}
                {ActifsList.map((item, index) => (
                <div key={index} className="rwt-about-area about-style-2 mt-5">
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="col-lg-5">
                                <div className="thumbnail">
                                    <img className="w-100" src={item.img} alt="About Images" />
                                </div>
                            </div>

                            <div className="col-lg-7 mt_md--30 mt_sm--30">
                                <div className="content">
                                    <div className="section-title">

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <h4 className="subtitle"><span className="theme-gradient">Détails.</span></h4>
                                            <h2 className="title mt--10">{item.title}</h2>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <p>{item.description}</p>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>   
                </div>
            ))}
                {/* FIN */}
                
                <Separator />
                
                {/* Liste des actifs numériques */}
                    <div className="service-details my-5">
                        <Slider {...settings}>
                            {ActifList?.map((item, index) => (
                                <div key={index} className="additional-image-item">
                                    <img src={item.image} alt={`Additional ${index}`} />
                                </div>
                            ))}
                        </Slider>
                    </div>
                {/* Fin Liste des actifs numériques */}

                <Separator />

                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default CryptoActifs
