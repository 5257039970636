import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FiArrowRight, FiCheck } from "react-icons/fi";

// Pour le Carousel slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Carousel } from 'bootstrap';

/**
 * Liste des image qui defillent.
 *
 * @type {Object[]}
 */
 const list = [
    {
        id: 1,
        image: '/images/wti/new/supply/supply-1.jpg',
    },
    {
        id: 2,
        image: '/images/wti/new/supply/supply-2.jpg',
    },
    {
        id: 3,
        image: '/images/wti/new/supply/supply-3.jpg',
    },
    {
        id: 4,
        image: '/images/wti/new/supply/supply-4.jpg',
    },
    {
        id: 5,
        image: '/images/wti/new/supply/supply-5.jpg',
    },
 ]

const CasSupplyChain = () => {
    // Paramètres pour le carrousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: "linear"
    };
    return (
        <div className="rwt-about-area about-style-2 ">
            <div className="container">
                <div className="row row--30 align-items-center mt-5">
                    <div className="col-lg-5">
                    <Slider {...settings}>
                            {list?.map((item, index) => (
                        <div key={index} className="thumbnail">
                            <img className="w-100" src={item.image} alt={`Additional ${index}`}/>
                        </div>
                            ))}
                    </Slider>

                    </div>

                    
                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                <ul className="list-iconCasApp">
                                    <li><span className="icon"><FiCheck /></span><b>Transparence et traçabilité :</b> La blockchain offre une visibilité complète sur le parcours des produits, améliorant la confiance des consommateurs.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Réduction des risques de contrefaçon :</b> Elle garantit l'authenticité des produits et l'origine des matières premières, sécurisant la supply chain.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Automatisation des accords :</b> Les contrats intelligents facilitent les transactions et les accords, réduisant les délais et les coûts opérationnels.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Amélioration de l'efficacité opérationnelle :</b> Elle optimise les processus de la chaîne d'approvisionnement, depuis la production jusqu'à la livraison.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Défis d'intégration et de standardisation :</b> L'adoption de la blockchain nécessite la surmontée des barrières techniques et la collaboration intersectorielle.</li>
                                </ul>



                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default CasSupplyChain;
