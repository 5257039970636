import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { FiArrowRight } from "react-icons/fi";
import Logo from "../logo/Logo";
import Typewriter from 'typewriter-effect';


const callToActionData = {
    title: "Wealthtech Innovations",
    subtitle: "Nous vous accompagnons de bout en bout, de l’acculturation de vos collaborateurs jusqu’à la mise en production de vos applications blockchain et vous conseillons pour vos projets liés aux actifs numériques.",
    btnText: "Contactez-nous",
}

const CalltoActionSeven = () => {
    function TypewriterComponent() {
        return (
          <div id="animation_">
            <Typewriter
              onInit={(typewriter) => {
                typewriter
                  .typeString('Wealthtech')
                  .pauseFor(500)
                  .deleteAll()
                  .typeString('Innovations')
                  .pauseFor(500)
                  .deleteAll()
                  .start();
              }}
              options={{
                autoStart: true,
                loop: true,
              }}
            />
          </div>
        );
    }
    return (
        <div className="rn-callto-action clltoaction-style-default style-7">
            <div className="container">
                <div className="row row--0 align-items-center content-wrapper">
                    <div className="col-lg-8 col-md-8">
                        <div className="inner">
                            <div className="content text-start">
                                <p className="image-container">
                                    <TypewriterComponent /> 
                                    <img src={"/images/wti/logo/logo.png"}/>
                                </p>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p className="subtitle">{callToActionData.subtitle}</p>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4">
                        <ScrollAnimation 
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}>
                            <div className="call-to-btn text-start mt_sm--20 text-md-end">
                                <a className="btn-default btn-icon" href="/contact">{callToActionData.btnText} <i className="icon"><FiArrowRight /></i></a>
                            </div>
                        </ScrollAnimation>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionSeven;