import React, { useEffect, useRef } from 'react';
import {Typed} from 'react-typed';
import ScrollAnimation from 'react-animate-on-scroll';
import SEO from "../../common/SEO";
import HeaderTwo from '../../common/header/HeaderTwo';
import {Link} from "react-router-dom";
import FooterTwo from '../../common/footer/FooterTwo';
import Separator from "../../elements/separator/Separator";
import Copyright from '../../common/footer/Copyright';
import CasAdministrationPublique from '../../components/wti/blockchain/casApplication/CasAdministrationPublique';





/**
 * Page des détails sur l'application de la blockchain dans l'administration publique.
 *
 * Ce composant met en lumière les innovations apportées par la blockchain dans l'administration publique,
 * illustrant des cas d'application spécifiques qui transforment le fonctionnement des services publics.
 * Il vise à éduquer et informer les visiteurs sur les avantages de la blockchain pour la transparence,
 * l'efficacité et la sécurité dans l'administration publique.
 *
 * @component
 * @returns {JSX.Element} Le composant React pour la page dédiée à la blockchain dans l'administration publique.
 */
const AdministrationPublique = () => {
    // Variable de l'url du frontend
    const API_URL_FRONTEND =process.env.REACT_APP_API_URL_FRONTEND;
    const typedTarget = useRef(null);


    /**
     * useEffect qui fait défiler les mots
     *
     * @type {Object[]}
     */
    useEffect(() => {
        const options = {
            
            strings: ["Transparence accrue", "Sécurité renforcée", "Efficacité améliorée", "Réduction de la fraude", "Défis d'intégration"],
            typeSpeed: 80,
            backSpeed: 5,
            backDelay: 1000,
            loop: true,
        };

        const typed = new Typed(typedTarget.current, options);

        return () => {
            typed.destroy();
        };
    }, []);

    
    return (
        <>
            <SEO
                title="Blockchain dans l'Administration Publique - Wealthtech Innovations"
                description="Explorez comment la blockchain révolutionne l'administration publique avec Wealthtech Innovations. Découvrez les cas d'application pratiques pour améliorer la transparence, l'efficacité et la sécurité des processus administratifs."
                canonicalUrl={`https://www.wealthtechinnovations.com/cas-application-blockchain/administration-publique`}
                ogTitle="Révolution Blockchain dans l'Administration Publique par Wealthtech Innovations"
                ogDescription="Wealthtech Innovations mène la charge dans l'implémentation de la blockchain pour transformer l'administration publique. Apprenez comment nos solutions apportent innovation et valeur ajoutée."
                ogUrl={`https://www.wealthtechinnovations.com/cas-application-blockchain/administration-publique`}
            />

            <main className="page-wrapper">
                <HeaderTwo btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 variation-default height-450 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/wti/new/administration/administrationBg-1.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner ">
                                    <h2 className=" text-center  display-one theme-gradient color-white "> Administration publique<br/>
                                    <span ref={typedTarget}></span>
                                    </h2>
                                    
                                    <p className="descriptionCasApp">
                                        La blockchain dans l'administration publique révolutionne la façon dont les données sont gérées, sécurisées et partagées au sein des gouvernements. Cette technologie offre une transparence accrue, une sécurité renforcée et une efficacité améliorée dans les processus administratifs. Elle permet de créer des registres publics inviolables pour la gestion des actifs, des identités numériques sécurisées pour les citoyens, et facilite les transactions en éliminant les intermédiaires. L'adoption de la blockchain peut réduire considérablement la fraude, les erreurs et les coûts, tout en améliorant la confiance du public envers les institutions. Cependant, son intégration pose des défis, notamment en termes de réglementation, de compatibilité avec les systèmes existants et de changement culturel au sein des administrations.
                                    </p>
                                    <div className="button-group text-center mb-3">
                                        <Link className="btn-default btn-medium round btn-icon" to="/cas-application">Cas application</Link>
                                        {/* <Link className="btn-default btn-medium btn-border round btn-icon" to="/contact">Contactez-nous <i className="icon"></i></Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />
                {/* <AboutTwo data={data[0]}/> */}

                {/* contenu */}
                <CasAdministrationPublique/>
                {/* FIN */}
                
                <Separator />
                

                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default AdministrationPublique
