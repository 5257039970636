import React from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import BreadcrumbOne from "../elements/breadcrumb/BreadcrumbOne";
import BlogPropTwo from './../components/blog/itemProp/BlogPropTwo';
/**
 * Composant Blogs pour afficher une liste d'articles sur la blockchain, les innovations financières
 * et d'autres sujets pertinents proposés par Wealthtech Innovations.
 * 
 * Ce composant sert de page principale pour la section blog du site Web, offrant aux visiteurs un aperçu
 * des derniers articles et insights publiés par Wealthtech Innovations. Il intègre un SEO optimisé pour
 * améliorer la visibilité du contenu dans les moteurs de recherche et sur les plateformes de médias sociaux.
 * 
 * Utilise le composant `SEO` pour définir les métadonnées spécifiques à la page qui améliorent son classement
 * SEO et sa présentation sur les réseaux sociaux. Le composant `Layout` englobe la structure générale de la
 * page, tandis que `BreadcrumbOne` fournit une navigation contextuelle pour améliorer l'expérience utilisateur.
 * `BlogPropTwo` est utilisé pour lister les articles de blog, permettant une personnalisation du style
 * et de la mise en page des entrées de blog.
 *
 * @component
 * @example
 * return (
 *   <Blogs />
 * )
 */
const Blogs= () => {
    // Variable de l'url du frontend
    const API_URL_FRONTEND =process.env.REACT_APP_API_URL_FRONTEND;
    return (
        <>
             <SEO 
                title="Derniers Articles sur la Blockchain et les Innovations | Wealthtech Innovations"
                description="Explorez les derniers articles et insights sur la blockchain, les crypto-monnaies, et les technologies financières disruptives chez Wealthtech Innovations."
                canonicalUrl={`https://www.wealthtechinnovations.com/blogs`}
                ogTitle="Explorez les Derniers Articles sur la Blockchain | Wealthtech Innovations"
                ogDescription="Dive into the latest blockchain trends, crypto news, and financial tech innovations with Wealthtech Innovations. Stay ahead with our insights."
                ogUrl={`https://www.wealthtechinnovations.com/blogs`}
            />
            <Layout>
                <BreadcrumbOne 
                    title="Les articles de Wealthtech Innovations."
                    rootUrl="/"
                    parentUrl="Accueil"
                    currentUrl="Blogs"
                />

                <div className="main-content">
                    {/* Start Blog Area  */}
                    <div className="rn-blog-area rn-section-gap">
                        <div className="container">
                            <div className="row mt_dec--30">
                                <BlogPropTwo column="col-lg-6 mt--30" StyleVarProp="box-card-style-default card-list-view" />
                            </div>
                        </div>
                    </div>
                    {/* End Blog Area  */}
                </div>

            </Layout>
        </>
    )
}

export default Blogs
