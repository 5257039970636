import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { FiArrowRight } from "react-icons/fi";

 
 
  

const testimonialData = [
    {
        image: 'projet-1',
        name: 'Stablecoin',
        designation: 'En cours',
        description: "La représentation numérique du franc CFA (XOF/XAF) via un contrat intelligent sur notre plateforme de Stablecoin va au-delà d'une simple monnaie électronique. Ce stablecoin, conçu sur la blockchain, se distingue par sa résilience face à la fraude et son registre transparent, offrant une solution sécurisée et fiable pour toutes les transactions.",
        // description:"Représentation numérique du franc CFA XOF/XAF par le biais d'un contrat intelligent.Bien plus qu'une monnaie électronique ce stablecoin conçu sur la blockchain est une solution résiliente à la fraude, avec un registre transparent",
        url:"https://stablecoin.chainsolutions.fr/"
       
    },
    {
        image: 'projet-2',
        name: 'E-vote',
        designation: 'En cours',
        // description:"Par le biais de contrat intelligent, nous avons conçu une plateforme de vote qui vous permet de créer des votes électroniques infalsifiables qui garantit la légitimité de tout scrutin. Pour expérimenter une participation transparente à la prise de décision par le biais de scrutin dont les résultats sont instantanés, vous pouvez utiliser notre plateforme Evote",
        description: "Grâce à des contrats intelligents, nous avons conçu Evote, une plateforme de vote électronique infalsifiable garantissant la légitimité des scrutins. Expérimentez une participation transparente avec des résultats instantanés et sécurisés. Utilisez Evote pour une prise de décision sûre, fiable et moderne dès aujourd'hui.",
        url:"https://evote.chainsolutions.fr/"
       
    },
    {
        image: 'projet-3',
        name: 'OPCVM',
        designation: 'En cours',
        description: "Notre plateforme de distribution transfrontalière de fonds d’investissement OPCVM révolutionne le secteur en offrant une solution intégrée et sécurisée pour la gestion et la distribution des fonds. Grâce à la technologie blockchain, nous garantissons transparence, sécurité et efficacité des transactions internationales.",
        url:"https://funds.chainsolutions.fr/"
       
    },
    {
        image: 'projet-4',
        name: 'Token Factory',
        designation: 'Terminée',
        description: "Notre plateforme Token Factory simplifie la création de jetons numériques sur la blockchain. Grâce à notre Factory de contrats intelligents, vous pouvez créer facilement votre token ERC20 en quelques étapes simples. Offrez à votre projet la puissance et la sécurité de la technologie blockchain avec notre solution intuitive.",
        url:"https://tokenfactory.wealthtechinnovations.com/"
       
    },
    {
        image: 'projet-5',
        name: 'Crowdfunding',
        designation: 'A venir',
        description: 'Notre plateforme de crowdfunding, ou financement participatif, révolutionne la manière de financer des projets innovants. Grâce à la technologie blockchain, nous assurons la transparence et la sécurité des transactions. Rejoignez notre communauté pour lever des fonds efficacement et concrétiser vos idées avec confiance et support.',
        url:"#"

      
    },
    {
        image: 'projet-6',
        name: 'Gestion',
        designation: 'A venir',
        description: "Notre plateforme de gestion des titres de propriété utilise la blockchain pour assurer la sécurité et la transparence des transactions. Simplifiez la gestion et le transfert des titres de propriété avec notre solution innovante, qui garantit l'intégrité des données et la réduction des fraudes grâce à la technologie blockchain.",
        url:"#"

    },
    {
        image: 'projet-7',
        name: 'Certification',
        designation: 'A venir',
        description: "Notre plateforme de certification des diplômes utilise la blockchain pour garantir l'authenticité et la sécurité des documents académiques. Simplifiez la vérification et la gestion des diplômes avec notre solution innovante, assurant une transparence totale et une protection contre la fraude grâce à la technologie blockchain.",
        url:"#"

    },
    {
        image: 'projet-8',
        name: 'Caritatif',
        designation: 'A venir',
        description: 'Notre plateforme de cryptomonnaie pour les initiatives caritatives et sociales utilise la blockchain pour assurer la transparence et la sécurité des dons. Soutenez des causes importantes avec une solution fiable et innovante, garantissant que chaque contribution est suivie et utilisée de manière responsable et efficace.',
        url:"#"

    },
]


const TestimonialOne = ({column , teamStyle}) => {
    return (
        <div className="row row--15">
            {testimonialData.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`rn-box-card ${teamStyle}`}>
                            <div className="inner">
                                <figure className="thumbnail">
                                    <img src={`./images/wti/new/projets/${data.image}.jpg`} alt="Corporate React Template" />
                                </figure>
                                <figcaption className="content">
                                    <p className="description">{data.description}</p>
                                    <h2 className="title">{data.name}</h2>
                                    <h6 className="subtitle theme-gradient">{data.designation}</h6>
                                    <div className="call-to-btn text-center mt--30">
                                        <a className="btn-default btn-icon" target="_blank" href={data.url} >Vers le site <i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </figcaption>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}

export default TestimonialOne;
