// Importations nécessaires
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { FiMenu, FiX } from 'react-icons/fi';
import Typewriter from 'typewriter-effect';

// import './SideCategories.css'; // Assurez-vous d'inclure le chemin correct

const SideCategories = () => {
    // Gérer l'état d'ouverture de la sidebar
    const [isOpen, setIsOpen] = useState(window.innerWidth > 768);

    // Ajuster l'état basé sur la taille de l'écran
    useEffect(() => {
        function handleResize() {
            setIsOpen(window.innerWidth > 768);
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Style pour les liens actifs
    const activeStyle = {
        color: 'white',
        backgroundColor: 'pink',
    };

    // Toggle la visibilité de la sidebar
    const toggleSidebar = () => setIsOpen(!isOpen);


    function TypewriterComponent() {
        return (
          <div id="animation_">
            <Typewriter
              onInit={(typewriter) => {
                typewriter
                  .typeString('Wealthtech')
                  .pauseFor(500)
                  .deleteAll()
                  .typeString('Innovations')
                  .pauseFor(500)
                  .deleteAll()
                  .start();
              }}
              options={{
                autoStart: true,
                loop: true,
              }}
            />
          </div>
        );
      }

    return (
        <>
            <div className="sidebar-toggle" onClick={toggleSidebar}>
                {isOpen ? <FiX size={24} /> : <FiMenu size={24} />}
            </div>
            <div className={`sidebar ${isOpen ? 'open' : ''}`}>
                <div className="sidebar-header image-container">
                    <TypewriterComponent /> 
                    <img src="/images/wti/logo/logo.png" alt="Logo" style={{ maxWidth: '80px', margin: '0 auto', display: 'block' }} />
                </div>
                <ul className="category-list">
                    <li>
                        <NavLink to="/profil/dashboard" activeStyle={activeStyle} exact>Dashboard</NavLink>
                    </li>
                    <li>
                        <NavLink to="/profil" activeStyle={activeStyle} exact>Profil</NavLink>
                    </li>
                    
                    <li>
                        <NavLink to="#" activeStyle={activeStyle} exact>Déconnexion</NavLink>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default SideCategories;
