import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import {Link} from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";

const BlockchainDefinition = () => {
    return (
        <div className="rwt-about-area about-style-2 ">
            <div className="container">
                {/* <h4 className="titl4 mt--10 text-center">Qui sommes-nous?</h4> */}

                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="/images/wti/new/blockchain/blockchain-3.jpg" alt="About Images" />
                        </div>
                    </div>

                    
                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">
                            <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                   <h2 className="titl4 mt--10 text-center">Qu’est-ce que la Blockchain ?</h2>

                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>
                                        Déployée à partir de 2008, la blockchain est une technologie de stockage et de transmission d’informations prenant la forme d'une base de données qui a la particularité d’être partagée simultanément avec tous ses utilisateurs.
                                        Cette technologie a pour avantage d'être rapide et sécurisée. Lorsque la blockchain est publique, elle peut fonctionner sans organe central de contrôle conformément au consensus de validation des transactions et des blocs. Lorsque la blockchain est privée, des règles d’accès et de fonctionnement peuvent être définies en amont : le consensus.
                                        Plus concrètement, la blockchain permet à ses utilisateurs, connectés en réseau, de partager et de consulter des données sans intermédiaire.
                                        Le champ d'application de la blockchain est bien plus large que celui des cryptomonnaies / cryptoactifs (assurance, sport, finance, immobilier, art, logistique, énergie, industrie, santé, agriculture, services, hôtellerie, ...)
                                    </p>
                                    
                                    <p>
                                        Blockchain publique :Lorsque l’historique des informations et des transactions d’une blockchain est accessible et ouvert à tout le monde, on parle de blockchain publique.
                                        Exemple : La Blockchain ETHEREUM (ETHscan) la Blockchain Binance Smart Chain (Bscan)
                                        Blockchain privée : Lorsqu’une Blockchain est conçue pour les besoins spécifiques d’une entreprise, d’une organisation ou d’un consortium, on parle d’une blockchain privée, car l’accès à l’inscription, à la consultation et au partage des informations de la blockchain peut être restreint à des utilisateurs bien définis.
                                        Les blockchains privées sont recommandées pour les entreprises et les institutions.
                                    </p>

                                    {/* <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> Les technologies blockchain.</li>
                                        <li><span className="icon"><FiCheck /></span> Les actifs numériques (Crypto-actifs).</li>
                                        
                                    </ul> */}
                                </ScrollAnimation>
                                {/* <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>
                                        Suite au constat d’absence d’informations et de formations sur le sujet de la blockchain en Afrique, nous avons décidé de mettre en place une structure pour promouvoir cette nouvelle technologie et les avantages liés à son implémentation.
                                        Nous avons notamment mis en place des partenariats avec des universités et des associations dans l'optique d'informer, de former et de concevoir des projets en ce sens.
                                    </p>
                                </ScrollAnimation> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default BlockchainDefinition;
