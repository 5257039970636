import React from 'react';
import { NavLink } from 'react-router-dom';

const Nav = () => {
    return (
        <ul className="mainmenu">
            {/* <li>
                <NavLink exact to="/"  activeStyle={{ color: 'blue' }} >Accueil</NavLink>
            </li> */}
            <li className="has-droupdown"><NavLink to="/">Accueil</NavLink>
                <ul className="submenu">
                    <li><NavLink to="/nos-missions">Notre mission</NavLink></li>
                </ul>
            </li>
            
            <li className="has-droupdown"><NavLink to="/nos-offres">Nos services</NavLink>
                <ul className="submenu">
                    <li><NavLink to="/nos-offres">Nos offres</NavLink></li>
                    <li><NavLink to="/conference-formations-blockchain">Conférences et formations blockchain</NavLink></li>
                    <li><NavLink to="/conseils-developpement">Conseils et développement</NavLink></li>
                    <li><NavLink to="/crypto-actifs">Crypto-actifs</NavLink></li>
                </ul>
            </li>
            <li>
                <NavLink to="/nos-projets" activeClassName="active-link">Nos projets</NavLink>
            </li>
            {/* <li>
                <NavLink to="/blockchain" activeClassName="active-link">Blockchain</NavLink>
            </li> */}
            <li className="has-droupdown"><NavLink to="/blockchain">Blockchain</NavLink>
                <ul className="submenu">
                    <li><NavLink to="/blockchain">Définition</NavLink></li>
                    <li><NavLink to="/cas-application">Cas d'application</NavLink></li>
                    <li><NavLink to="/lexique">Lexique</NavLink></li>
                </ul>
            </li>
            {/* <li>
                <NavLink to="/blogs" activeClassName="active-link">Blogs</NavLink>
            </li> */}
            <li>
                <NavLink to="/contact" activeClassName="active-link">Contactez-nous</NavLink>
            </li>
        </ul>
    );
};

export default Nav;
