import PropTypes from "prop-types";
import React from 'react';
import {Link} from "react-router-dom";

const Logo = ({ image, className }) => {
    return (
        <div className={`logo ${className}`}>
            <Link to={"/"}>
                <img src={image} alt="Corporate Logo" />
            </Link>
        </div>
    );
};

Logo.propTypes = {
    image: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export default Logo;
