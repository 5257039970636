import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import {Link} from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";
/*
* Le composant AboutTwo affiche des informations sur une entreprise avec des animations.
*
* @component
* @param {Object} props - Les propriétés transmises au composant.
* @param {Object[]} props.data - Un tableau d'objets contenant des images et des informations supplémentaires.
* @param {string} props.data.title - Le titre de la section spécifique.
* @param {string} props.data.description - La description de la section spécifique.
* @returns {JSX.Element} Composant React
*/
const AboutTwo = ({ data }) => {
    return (
        <>
            {data.additionalImages.map((item, index) => (
                <div key={index} className="rwt-about-area about-style-2 mt-5">
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="col-lg-5">
                                <div className="thumbnail">
                                    <img className="w-100" src="/images/about/about-3.png" alt="About Images" />
                                </div>
                            </div>

                            <div className="col-lg-7 mt_md--30 mt_sm--30">
                                <div className="content">
                                    <div className="section-title">

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <h4 className="subtitle"><span className="theme-gradient">Détails.</span></h4>
                                            <h2 className="title mt--10">{item.title}</h2>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <p>{item.description}</p>
                                        </ScrollAnimation>
                                        {/* <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <div className="read-more-btn mt--40">
                                                <Link className="btn-default btn-icon" to="#">More About Us <i className="icon"><FiArrowRight /></i></Link>
                                            </div>
                                        </ScrollAnimation> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>   
                </div>
            ))}
        </>
    )
}

export default AboutTwo;
