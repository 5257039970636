import React, { useEffect, useRef } from 'react';
import {Typed} from 'react-typed';
import { useParams } from 'react-router-dom'; // Importez useParams
import SEO from "../common/SEO";
import HeaderTwo from '../common/header/HeaderTwo';
import {Link} from "react-router-dom";
import HeaderTopNews from '../common/header/HeaderTopNews';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import { FiArrowRight } from "react-icons/fi";
import Separator from "../elements/separator/Separator";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import AboutTwo from "../elements/about/AboutTwo";
import CalltoActionSix from "../elements/calltoaction/CalltoActionSix";
import PricingFive from "../elements/pricing/PricingFive";
import BrandOne from "../elements/brand/BrandOne";
import SectionTitle from "../elements/sectionTitle/SectionTitle";

// Pour le Carousel slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Carousel } from 'bootstrap';

/**
 * Liste des services avec des détails pour chaque service.
 *
 * @type {Object[]}
 */
const ServiceList = [
    {
        id: 1,
        image: '/images/wti/services/conferences-formations.png',
        title: 'Conférences et formations blockchain',
        description: 'There are many variations of passages of Lorem Ipsum available, but the majority.',
        details: "sembler difficiles à comprendre. C’est la raison pour laquelle nous proposons des interventions pédagogiques sur mesure, adaptées au niveau et au métier des participants. Ces interventions permettent de faire comprendre simplement les enjeux, les usages et le fonctionnement des blockchains et des actifs numériques.",
        additionalImages: [
            { img: '/images/wti/services/Formations-techniques.png', title: 'Formations techniques', description: 'Pour les développeurs, nous proposons des formations techniques qui leur permettront de concevoir des architectures blockchains spécifiques et adaptées au domaine d’activité.'},
            { img: '/images/wti/services/Interventions-comex.png', title: 'Interventions COMEX', description: 'Nous intervenons auprès de vos dirigeants afin de leur donner une vision stratégique de l’utilité des blockchains et cryptoactifs.'},
            { img: '/images/wti/services/Intervention-métiers-réglementées.png', title: 'Interventions métiers réglementés', description: 'Nous intervenons auprès des professions règlementées dans l’optique de proposer des solutions basées sur les blockchains et actifs numériques : « specific use cases ».'},
            { img: '/images/wti/services/Start-operations-icon.png', title: 'Interventions Institutions publiques et privée', description: 'Nous intervenons auprès des institutions publiques et privées en tant que consultants ou formateurs afin de donner une vision stratégique de l’utilité des blockchains et cryptoactifs. Notre intervention peut s’étendre à la réflexion ou la réalisation de projet concrets.'},
            { img: '/images/wti/services/Initiation-cryptos-actifs.png', title: 'Conférences tout public', description: 'Nous proposons des conférences en ligne grand public pour acculturer les particuliers, les équipes et les métiers aux impacts et avantages des blockchains et cryptoactifs.'},
            // Ajoutez 3 autres objets pour les images supplémentaires
        ]
        
    },
    {
        id: 2,
        image: '/images/wti/services/conseil-developpement.png',
        title: 'Conseils et développement',
        description: 'Passages there are many variations of Lorem Ipsum available, but the majority have suffered.',
        details: "Notre équipe d’experts vous accompagne à chaque étape de vos projets blockchain.",
        additionalImages: [
            { img: '/images/wti/services/Consulting-et-études.png', title: 'Consulting : Etudes et conseils stratégiques', description: "Exploration des cas d’usage, études, analyse. Nos expertises sont au service de vos idées et de votre entreprise pour vous permettre d'imaginer de nouveaux services et d'assurer une implémentation réussie de la blockchain dans votre activité."},
            { img: '/images/wti/services/Analyse-et-optimisation.png', title: 'Analyse et optimisation des projets', description: "Nous étudions en détail votre projet et vous proposons des plans fonctionnels et techniques. Nous analysons votre projet afin de réduire les risques, les coûts associés et garantir une utilisation optimale de votre blockchain."},
            { img: '/images/wti/services/Développement-et-implementation-sur-mesure.png', title: 'Développement et implémentation de solutions sur-mesure', description: "Notre équipe de developpeurs et de consultants « métiers » met tout en œuvre pour assurer la bonne réalisation et l’implémentation de vos projets sur-mesure, de l’expression du besoin à la réalisation du POC, la mise en production et la livraison de votre solution."},
            { img: '/images/wti/services/002-project.png', title: 'Audits et diligences techniques et règlementaires', description: "Nous auditons vos projets ou vos solutions blockchain existantes, sur le plan technique, juridique et de gouvernance. Nos analyses sont suivies de recommandations en vue d’une amélioration ou d’un accompagnement dans le cadre d’un partenariat stratégique."},
            // Ajoutez 3 autres objets pour les images supplémentaires
        ]
    },
    
    {
        id: 3,
        image: '/images/wti/services/crypto-actifs.png',
        title: 'Actifs numériques',
        description: 'Variations There are many passages of Lorem Ipsum available, but the majority but the majority.',
        details: "Nous vous proposons des services et formations pour comprendre l’utilité des actifs numériques, partant de l’analyse des projets listés à la détention et l’échange des actifs numériques.",
        additionalImages: [
            { img: '/images/wti/services/liquidity-provider.png', title: 'Liquidity provider', description: "Nous assurons la liquidité de vos actifs numériques en devises FIAT"},
            { img: '/images/wti/services/Initiation-cryptos-actifs.png', title: 'Initiation : Crypto-actifs', description: "Nous proposons un programme d’initiation aux cryptos actifs au cours duquel vous saurez comment détenir des actifs numériques, comment les échanger et comment analyser un actif numérique"},
            { img: '/images/wti/services/Création-de-jeton.png', title: 'Création d’actifs numériques', description: "Nous vous accompagnons dans votre projet de création de jetons fongibles « cryptomonnaies » et de jetons non fongibles « NFT ». De l’idée à la conception des jetons, à la constitution des galeries d’art numérique. Nous vous aidons dans le choix de la plateforme de développement et dans la stratégie de communication. De plus, nous prenons en charge le lancement des processus ICO, IEO, FTO, INO."},
            // Ajoutez 3 autres objets pour les images supplémentaires
        ]
     
    }
];

/**
 * Liste des actifs qui s'affiche quand on clique le service crypto-actif.
 *
 * @type {Object[]}
 */
 const ActifList = [
    {
        id: 1,
        image: '/images/wti/actif/actif-1.png',
    },
    {
        id: 2,
        image: '/images/wti/actif/actif-2.png',
    },
    
    {
        id: 3,
        image: '/images/wti/actif/actif-3.png',
    },

    {
        id: 4,
        image: '/images/wti/actif/actif-4.png',
    },

    {
        id: 5,
        image: '/images/wti/actif/actif-5.png',
    },

    {
        id: 6,
        image: '/images/wti/actif/actif-6.png',
    },

    {
        id: 7,
        image: '/images/wti/actif/actif-7.png',
    },

    {
        id: 8,
        image: '/images/wti/actif/actif-8.png',
    },
];





/**
 * Page des détails du service.
 *
 * @component
 * @returns {JSX.Element} Composant React
 */
const DetailsService = () => {
    const { id } = useParams(); // Utilisez useParams pour obtenir l'ID
    const blogId = parseInt(id, 10);
    /**
     * Filtre les données du service en fonction de l'ID fourni.
     *
     * @type {Object[]}
     */
     const data = ServiceList.filter(blog => blog.id === blogId);

    /**
     * Fait les conditions en fonction des services et récupère leur titre ensuite il repartit les mots qui vont défilé aux variables.
     *
     * @type {Object[]}
     */
    const typedTarget = useRef(null);
    let textA = ""
    let textB = ""
    let textC = ""
    if (data[0]?.id===1) {
        textA= "Conférences et",
        textB= "formations",
        textC= "blockchain"
    }else if(data[0]?.id===2){
        textA= "Conseils et",
        textB= "développement",
        textC= ""
    }else if(data[0]?.id===3){
        textA= "Actif ",
        textB= "numérique",
        textC= ""
    }
    /**
     * useEffect qui fait défiler les mots
     *
     * @type {Object[]}
     */
    useEffect(() => {
        const options = {
            
            strings: [textA, textB, textC],
            typeSpeed: 80,
            backSpeed: 5,
            backDelay: 1000,
            loop: true,
        };

        const typed = new Typed(typedTarget.current, options);

        return () => {
            typed.destroy();
        };
    }, [textA, textB, textC]);

    // Paramètres pour le carrousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: "linear"
    };


    
    return (
        <>
            <SEO title="Détails des services" />
            <main className="page-wrapper">
                <HeaderTwo btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-6 shape-right height-750">
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="order-2 order-lg-1 col-lg-6 mt_md--50 mt_sm--50 mt_lg--30">
                                <div className="inner text-start">
                                    <h2 className=" theme-gradient color-white">Détails du service<br />
                                        <span ref={typedTarget}></span>
                                    
                                    </h2>
                                    <p className="description color-white">Ici, nous exposons en détail les spécificités de nos divers services.</p>
                                    <div className="button-group mt--30 mt_sm--20">
                                        <Link className="btn-default btn-medium btn-icon btn-border"  to="/nos-offres">Nos offres <i className="icon"><FiArrowRight /></i></Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail">
                                    <img src="/images/banner/banenr-image-02.png" alt="Banner Images" />
                                </div>
                            </div>
                            <div className="shape-image">
                                <img src="/images/banner/white-shape.svg" alt="Banner Images" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}


                <Separator />
                <AboutTwo data={data[0]}/>
                
                <Separator />
                {data[0]?.id==3 && (
                    <div className="service-details">
                        <Slider {...settings}>
                            {ActifList?.map((item, index) => (
                                <div key={index} className="additional-image-item">
                                    <img src={item.image} alt={`Additional ${index}`} />
                                </div>
                            ))}
                        </Slider>
                    </div>
                )}
                <Separator />

                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default DetailsService
