import React, { useEffect, useRef } from 'react';
import {Typed} from 'react-typed';
import ScrollAnimation from 'react-animate-on-scroll';
import SEO from "../../common/SEO";
import HeaderTwo from '../../common/header/HeaderTwo';
import {Link} from "react-router-dom";
import FooterTwo from '../../common/footer/FooterTwo';
import Separator from "../../elements/separator/Separator";
import Copyright from '../../common/footer/Copyright';
import CasAgroIndustrieAgriculture from '../../components/wti/blockchain/casApplication/CasAgroIndustrieAgriculture';





/**
 * Page détaillant l'application de la blockchain dans l'agro-industrie et l'agriculture.
 *
 * Ce composant expose les avantages de l'application de la blockchain dans le secteur agro-industriel
 * et agricole, illustrant comment cette technologie peut apporter des améliorations significatives
 * en termes de traçabilité, d'efficacité et de sécurité. Il vise à informer les utilisateurs sur
 * les potentiels de la blockchain pour transformer la chaîne de valeur alimentaire, en s'appuyant sur
 * des études de cas et des projets concrets.
 *
 * @returns {JSX.Element} Le composant React pour la page dédiée à la blockchain dans l'agro-industrie et l'agriculture.
 */
const AgroIndustrieAgriculture = () => {
    // Variable de l'url du frontend
    const API_URL_FRONTEND =process.env.REACT_APP_API_URL_FRONTEND;
    const typedTarget = useRef(null);


    /**
     * useEffect qui fait défiler les mots
     *
     * @type {Object[]}
     */
    useEffect(() => {
        const options = {
            
            strings: ["Transparence et traçabilité", "Transactions sécurisées", "Optimisation", "Gestion durable", "Défis de mise en œuvre"],
            typeSpeed: 80,
            backSpeed: 5,
            backDelay: 1000,
            loop: true,
        };

        const typed = new Typed(typedTarget.current, options);

        return () => {
            typed.destroy();
        };
    }, []);

    
    return (
        <>
            <SEO
                title="Blockchain dans l'Agro-Industrie et l'Agriculture - Wealthtech Innovations"
                description="Explorez l'impact révolutionnaire de la blockchain dans l'agro-industrie et l'agriculture avec Wealthtech Innovations. Découvrez comment cette technologie apporte traçabilité, efficacité et sécurité, transformant la chaîne de valeur alimentaire."
                canonicalUrl={`https://www.wealthtechinnovations.com/cas-application-blockchain/agro-industrie-agriculture`}
                ogTitle="Révolutionnez l'Agro-Industrie et l'Agriculture avec la Blockchain - Wealthtech Innovations"
                ogDescription="Chez Wealthtech Innovations, nous démontrons comment la blockchain peut redéfinir l'agro-industrie et l'agriculture, en offrant des solutions pour une chaîne de valeur alimentaire plus sûre, transparente et efficiente."
                ogUrl={`https://www.wealthtechinnovations.com/cas-application-blockchain/agro-industrie-agriculture`}
            />

            <main className="page-wrapper">
                <HeaderTwo btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 variation-default height-450 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/wti/new/industrie/industrieBg-1.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner ">
                                    <h2 className="text-center display-one theme-gradient color-white"> Agro-industrie/agriculture<br/>
                                    <span ref={typedTarget}></span>
                                    </h2>
                                    
                                    <p className="descriptionCasApp">
                                        L'intégration de la blockchain dans l'agro-industrie et l'agriculture promet de transformer ces secteurs en améliorant la transparence, la traçabilité et l'efficacité des chaînes d'approvisionnement. Cette technologie permet de créer des registres inaltérables pour chaque produit, de la ferme à la table, assurant ainsi une confiance accrue chez les consommateurs concernant l'origine et la qualité des produits. La blockchain facilite également les transactions financières sécurisées entre tous les acteurs du secteur, des agriculteurs aux distributeurs, en réduisant les coûts associés aux intermédiaires. De plus, elle offre des solutions innovantes pour le suivi de l'utilisation des ressources, la certification des produits biologiques et la gestion durable des terres. Malgré ses nombreux avantages, l'adoption de la blockchain dans ces secteurs est freinée par des défis techniques, réglementaires et d'acceptation par les utilisateurs. Pour réaliser pleinement son potentiel, une collaboration étroite entre les technologues, les agriculteurs, les entreprises agroalimentaires et les régulateurs est essentielle.  
                                        
                                    </p>
                                    <div className="button-group text-center mb-3">
                                        <Link className="btn-default btn-medium round btn-icon" to="/cas-application">Cas application</Link>
                                        {/* <Link className="btn-default btn-medium btn-border round btn-icon" to="/contact">Contactez-nous <i className="icon"></i></Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />
                {/* <AboutTwo data={data[0]}/> */}

                {/* contenu */}
                <CasAgroIndustrieAgriculture/>
                {/* FIN */}
                
                <Separator />
                

                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default AgroIndustrieAgriculture
