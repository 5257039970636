import React, { useEffect, useRef } from 'react';
import {Typed} from 'react-typed';
import ScrollAnimation from 'react-animate-on-scroll';
import SEO from "../../common/SEO";
import HeaderTwo from '../../common/header/HeaderTwo';
import {Link} from "react-router-dom";
import FooterTwo from '../../common/footer/FooterTwo';
import Separator from "../../elements/separator/Separator";
import Copyright from '../../common/footer/Copyright';
import CasBanqueFinance from '../../components/wti/blockchain/casApplication/CasBanqueFinance';





/**
 * Page détaillant l'application de la blockchain dans le secteur bancaire et financier.
 *
 * Ce composant présente comment Wealthtech Innovations exploite la blockchain pour innover
 * dans le domaine de la banque et de la finance, soulignant les avantages de cette technologie
 * pour améliorer la sécurité, la transparence et l'efficacité des opérations financières.
 * Les visiteurs peuvent découvrir des études de cas et des initiatives développées spécifiquement
 * pour ce secteur.
 *
 * @returns {JSX.Element} Le composant React pour la page dédiée à l'application blockchain dans la banque et la finance.
 */
const BanqueFinance = () => {
    // Variable de l'url du frontend
    const API_URL_FRONTEND =process.env.REACT_APP_API_URL_FRONTEND;
    const typedTarget = useRef(null);


    /**
     * useEffect qui fait défiler les mots
     *
     * @type {Object[]}
     */
    useEffect(() => {
        const options = {
            
            strings: ["Transactions accélérées", "Renforcement de la sécurité", "Conformité réglementaire", "Défis d'intégration"],
            typeSpeed: 80,
            backSpeed: 5,
            backDelay: 1000,
            loop: true,
        };

        const typed = new Typed(typedTarget.current, options);

        return () => {
            typed.destroy();
        };
    }, []);

    
    return (
        <>
            <SEO
                title="Blockchain dans Banque et Finance - Wealthtech Innovations"
                description="Découvrez comment la blockchain transforme le secteur bancaire et financier avec Wealthtech Innovations. Explorez nos cas d'application pour améliorer la sécurité, la transparence et l'efficacité des transactions financières."
                canonicalUrl={`https://www.wealthtechinnovations.com/cas-application-blockchain/banque-finance`}
                ogTitle="Réinventez la Banque et la Finance avec la Blockchain - Wealthtech Innovations"
                ogDescription="Wealthtech Innovations dévoile le potentiel de la blockchain pour révolutionner le secteur bancaire et financier. Découvrez nos projets innovants visant à sécuriser et optimiser les services financiers."
                ogUrl={`https://www.wealthtechinnovations.com/cas-application-blockchain/banque-finance`}
            />

            <main className="page-wrapper">
                <HeaderTwo btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 variation-default height-450 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/wti/new/banque/banqueBg-1.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner ">
                                    <h2 className="text-center display-one theme-gradient color-white"> Banque et finance<br/>
                                    <span ref={typedTarget}></span>
                                    </h2>
                                    
                                    <p className="descriptionCasApp">
                                        offrant transparence, sécurité accrue, et efficacité opérationnelle. Cette technologie permet de réaliser des transactions quasi instantanées à moindre coût, de réduire les risques de fraude, et d'améliorer la conformité réglementaire. Elle révolutionne les systèmes de paiement, le financement du commerce international, les prêts syndiqués, et la gestion des actifs, en permettant une traçabilité et une vérifiabilité sans précédent des informations financières. La blockchain ouvre également la porte à l'innovation en matière de produits financiers, tels que les cryptomonnaies, les smart contracts, et les tokens non fongibles (NFT), offrant des possibilités nouvelles pour l'investissement et la diversification des portefeuilles. Toutefois, sa mise en œuvre dans l'ensemble du secteur nécessite de surmonter d'importants défis techniques, réglementaires, et organisationnels. La collaboration entre les institutions financières, les régulateurs, et les fournisseurs de technologies est cruciale pour exploiter pleinement le potentiel de la blockchain et transformer le paysage financier.
                                    </p>
                                    <div className="button-group text-center mb-3">
                                        <Link className="btn-default btn-medium round btn-icon" to="/cas-application">Cas application</Link>
                                        {/* <Link className="btn-default btn-medium btn-border round btn-icon" to="/contact">Contactez-nous <i className="icon"></i></Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />
                {/* <AboutTwo data={data[0]}/> */}

                {/* contenu */}
                <CasBanqueFinance/>
                {/* FIN */}
                
                <Separator />
                

                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default BanqueFinance
