import React, { useEffect, useRef } from 'react';
import {Typed} from 'react-typed';
import ScrollAnimation from 'react-animate-on-scroll';
import SEO from "../../common/SEO";
import HeaderTwo from '../../common/header/HeaderTwo';
import {Link} from "react-router-dom";
import FooterTwo from '../../common/footer/FooterTwo';
import Separator from "../../elements/separator/Separator";
import Copyright from '../../common/footer/Copyright';
import CasSante from '../../components/wti/blockchain/casApplication/CasSante';





/**
 * Page détaillant l'application de la blockchain dans le secteur de la santé.
 *
 * Ce composant met en avant l'utilisation de la blockchain pour innover dans le domaine de la santé,
 * soulignant les avantages de cette technologie pour sécuriser les données médicales, optimiser
 * les traitements et améliorer la gestion des dossiers des patients. Les visiteurs peuvent découvrir
 * des études de cas et des initiatives développées par Wealthtech Innovations pour transformer les
 * services de santé.
 *
 * @returns {JSX.Element} Le composant React pour la page dédiée à l'application blockchain dans la santé.
 */
const Sante = () => {
    // Variable de l'url du frontend
    const API_URL_FRONTEND =process.env.REACT_APP_API_URL_FRONTEND;
    const typedTarget = useRef(null);


    /**
     * useEffect qui fait défiler les mots
     *
     * @type {Object[]}
     */
    useEffect(() => {
        const options = {
            
            strings: ["Sécurité et confidentialité", "Amélioration", "Lutte contre la contrefaçon", "Automatisation", "Défis réglementaires"],
            typeSpeed: 80,
            backSpeed: 5,
            backDelay: 1000,
            loop: true,
        };

        const typed = new Typed(typedTarget.current, options);

        return () => {
            typed.destroy();
        };
    }, []);

    
    return (
        <>
            <SEO
                title="Blockchain dans la Santé - Solutions Innovantes par Wealthtech Innovations"
                description="Explorez comment la blockchain transforme le secteur de la santé avec Wealthtech Innovations. Découvrez nos cas d'application pour améliorer la sécurité des données, l'efficacité des traitements et la gestion des dossiers médicaux."
                canonicalUrl={`https://www.wealthtechinnovations.com/cas-application-blockchain/sante`}
                ogTitle="Révolutionnez le Secteur de la Santé avec la Blockchain - Wealthtech Innovations"
                ogDescription="Wealthtech Innovations démontre le potentiel de la blockchain pour révolutionner le secteur de la santé. Découvrez comment nos solutions blockchain offrent une meilleure gestion des données médicales et optimisent les soins aux patients."
                ogUrl={`https://www.wealthtechinnovations.com/cas-application-blockchain/sante`}
            />

            <main className="page-wrapper">
                <HeaderTwo btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 variation-default height-450 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/wti/new/sante/santeBg-1.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner ">
                                    <h2 className="text-center display-one theme-gradient color-white"> Santé<br/>
                                    <span ref={typedTarget}></span>
                                    </h2>
                                    
                                    <p className="descriptionCasApp">
                                        La blockchain offre des perspectives révolutionnaires dans le secteur de la santé, en améliorant la sécurité, la transparence et l'efficacité de la gestion des données médicales. Cette technologie promet de sécuriser les échanges d'informations de santé entre les différentes parties prenantes, en garantissant l'intégrité et la confidentialité des données des patients. Avec la blockchain, les dossiers médicaux peuvent être stockés de manière inaltérable et accessibles uniquement par des entités autorisées, facilitant ainsi une meilleure coordination des soins. Elle permet également la création de systèmes de suivi des médicaments de leur fabrication à leur distribution, luttant contre la contrefaçon et garantissant la qualité des traitements. De plus, la blockchain peut automatiser les processus de facturation et de paiement, réduisant les fraudes et les erreurs administratives. Cependant, l'adoption de cette technologie dans la santé fait face à des défis majeurs, tels que des questions réglementaires, la nécessité d'une interopérabilité entre les différents systèmes de santé, et la protection de la vie privée des patients. La collaboration entre les professionnels de santé, les régulateurs et les innovateurs technologiques est cruciale pour surmonter ces obstacles et exploiter pleinement le potentiel de la blockchain pour transformer le secteur de la santé.
                                    </p>
                                    <div className="button-group text-center mb-3">
                                        <Link className="btn-default btn-medium round btn-icon" to="/cas-application">Cas application</Link>
                                        {/* <Link className="btn-default btn-medium btn-border round btn-icon" to="/contact">Contactez-nous <i className="icon"></i></Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />
                {/* <AboutTwo data={data[0]}/> */}

                {/* contenu */}
                <CasSante/>
                {/* FIN */}
                
                <Separator />
                

                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default Sante
