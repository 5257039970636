import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import {Link} from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";

const QuiSommesNous = () => {
    return (
        <div className="rwt-about-area about-style-2 ">
            <div className="container">
                {/* <h4 className="titl4 mt--10 text-center">Qui sommes-nous?</h4> */}

                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="/images/wti/new/accueils/accueil-1.jpg" alt="About Images" />
                        </div>
                    </div>

                    
                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">
                            <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                   <h2 className="titl4 mt--10 text-center">Qui sommes-nous?</h2>

                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>
                                        Wealthtech innovations est une start-up spécialisée dans le développement technique d’applications blockchain et dans la promotion d’une nouvelle économie numérique.<br/>
                                        Nous avons pour objectif d’être un des leaders africains de l'accompagnement stratégique et technique sur :
                                    </p>
                                    

                                    <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> Les technologies blockchain.</li>
                                        <li><span className="icon"><FiCheck /></span> Les actifs numériques (Crypto-actifs).</li>
                                        
                                    </ul>
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>
                                        Suite au constat d’absence d’informations et de formations sur le sujet de la blockchain en Afrique, nous avons décidé de mettre en place une structure pour promouvoir cette nouvelle technologie et les avantages liés à son implémentation.
                                        Nous avons notamment mis en place des partenariats avec des universités et des associations dans l'optique d'informer, de former et de concevoir des projets en ce sens.
                                    </p>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default QuiSommesNous;
