import React from 'react'
import { Helmet } from 'react-helmet';

const SEO = ({
  title,
  description,
  canonicalUrl,
  ogTitle,
  ogDescription,
  ogUrl,
  ogType = 'website',
  twitterCard = 'summary_large_image'
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description || "Plateforme officielle de Wealthtech Innovations"} />
      <link rel="canonical" href={canonicalUrl} />
      <meta property="og:title" content={ogTitle || title} />
      <meta property="og:description" content={ogDescription || description} />
      <meta property="og:url" content={ogUrl || canonicalUrl} />
      <meta property="og:type" content={ogType} />
      <meta name="twitter:card" content={twitterCard} />
      <meta name="keywords" content={"Wealthtech Innovations, Monnaie numérique, Actif numérique, Blockchain, Solidity"} />
      {/* <meta name="keywords" content={"Wealthtech Innovations, Monnaie numérique, Actif numérique, Blockchain, Solidity"} /> */}
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    </Helmet>
  );
};

export default SEO;





// import PropTypes from "prop-types";
// import React from 'react'

// const SEO = ({ title, description,keywords }) => {
//     return (
//         <>
//             <meta charSet="utf-8" />
//             <title>{title ? `${title} || Wealthtech Innovations` : "Wealthtech Innovations"}</title>
//             <meta name="robots" content="noindex, follow" />
//             <meta name="description" content={description || "Plateforme officielle de Wealthtech Innovations"} />
//             <meta name="keywords" content={keywords || "Stablecoin, Monnaie numérique, Actif numérique, Blockchain, Solidity"} />
//             <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
//             <link rel="canonical" href="#" />
//         </>
//     )
// }

// SEO.propTypes = {
//     title: PropTypes.string,
//     description: PropTypes.string, // Ajout de la propType pour description
//     keywords:PropTypes.string
// };

// export default SEO;










// import PropTypes from "prop-types";
// import React from 'react'


// const SEO = ( {title} ) => {
//     return (
//         <>
//             <meta charSet="utf-8" />
//             <title>{title} || Wealthtech Innovations</title>
//             <meta name="robots" content="noindex, follow" />
//             <meta name="description" content="Plateforme officielle de Wealthtech Innovations" />
//             <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
//         </>
//     )
// }
// SEO.propTypes = {
//     title: PropTypes.string
// };

// export default SEO;


