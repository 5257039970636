/**
 * Composant pour la page nos projets.
 *
 * @component
 * @returns {JSX.Element} Composant React
 */
import React, { useEffect, useRef } from 'react';
import {Typed} from 'react-typed';
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import HeaderTwo from '../common/header/HeaderTwo';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import TestimonialOne from '../elements/testimonial/TestimonialOne';



/**
 * Page  "Nos Projets" chez Wealthtech Innovations.
 *
 * Ce composant met en avant les projets clés de Wealthtech Innovations qui utilisent la technologie blockchain
 * pour apporter des solutions innovantes aux problématiques d'inclusion financière, de sécurité des données,
 * de certification et de développement durable, principalement en Afrique.
 * Il offre une vue d'ensemble des missions et visions de l'entreprise à travers des témoignages et des descriptions
 * de projets en cours ou à venir.
 *
 * @returns {JSX.Element} Le composant React pour la page d'accueil présentant nos projets.
 */
const NosProjets = () => {
    // Variable de l'url du frontend
    const API_URL_FRONTEND =process.env.REACT_APP_API_URL_FRONTEND;
    const typedTarget = useRef(null);

    useEffect(() => {
        const options = {
            strings: ["Stablecoin.", "OPCVM.", "E-vote.", "Token factory."],
            typeSpeed: 80,
            backSpeed: 5,
            backDelay: 1000,
            loop: true,
        };

        const typed = new Typed(typedTarget.current, options);

        return () => {
            typed.destroy();
        };
    }, []);

    return (
        <>
            <SEO
                title="Nos Projets - Innovation Blockchain par Wealthtech Innovations"
                description="Explorez les projets pionniers de Wealthtech Innovations, centrés sur la blockchain pour adresser les défis d'inclusion, de sécurité, et de développement durable en Afrique. Découvrez comment nos initiatives transforment le paysage numérique."
                canonicalUrl={`https://www.wealthtechinnovations.com/nos-projets`}
                ogTitle="Transformez l'Avenir avec les Projets Blockchain de Wealthtech Innovations"
                ogDescription="Wealthtech Innovations mène des projets innovants en blockchain, développés par des talents africains, pour répondre aux enjeux contemporains d'inclusion financière, de certification et de développement durable."
                ogUrl={`https://www.wealthtechinnovations.com/nos-projets`}
            />

            <main className="page-wrapper">
                <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" />

                 {/* Start Slider Area  */}

                <div className="slider-area slider-style-1 bg-transparent py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <h1 className="title theme-gradient display-two">Nos projets <br />
                                        <span ref={typedTarget}></span>
                                    </h1>
                                    <p className="description">Nous aidons nos clients à réussir dans le numérique, en se concentrant sur la technologie blockchain.</p>
                                    <div className="button-group">
                                        {/* <a className="btn-default btn-medium round btn-icon" target="_blank" href="https://themeforest.net/checkout/from_item/33571911?license=regular">Vers nos missions<i className="icon"><FiArrowRight /></i></a> */}
                                        <Link className="btn-default btn-medium round btn-icon" to="/nos-missions">Vers nos missions</Link>
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="/contact">Contactez-nous <i className="icon"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />                            

                {/* Partie qui sommes nous */}
                <br/><br/>
                <div className="rwt-testimonial-area rn-section-gap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Tout ce qui concerne nos projets"
                                        title = "Nos projets."
                                        description = "Dans l’optique d’implémenter la blockchain en Afrique, WEALTHTECH INNOVATIONS porte plusieurs projets innovants qui seront réalisés et développés par des étudiants et développeurs africains. Ces projets innovants seront implémentés en utilisant les principes de conception de la blockchain. L’aboutissement de ces projets apportera des solutions pouvant répondre aux problématiques d’inclusion, de sécurité, de certification, de conservation des registres, de préservation des droits et de développement durable."
                                    />
                            </div>
                        </div>
                        <TestimonialOne column="col-lg-4 col-md-6 col-sm-6 col-12 mt--30" teamStyle="card-style-default testimonial-style-one style-two" />
                    </div>
                </div>
                {/* Fin partie qui sommes nous */}

                {/* Start Call To Action Area  */}
                <div className="rwt-callto-action-area">
                    <div className="wrapper">
                        {/* <CalltoActionFive /> */}
                    </div>
                </div>
                {/* End Call To Action Area  */}
                

                <Separator />                               
               
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default NosProjets;