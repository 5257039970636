import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Remplacez par useHistory si vous utilisez react-router-dom v5.
import Loading from './../../components/wti/Loading';
import { magic } from './../../magic';
// import { BrowserRouter } from 'react-router-dom'; //history.push('/home');


export default function Callback() {
  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY_WEALTHTECH =process.env.REACT_APP_API_KEY_WEALTHTECH;

  const navigate = useNavigate(); // Utilisez useHistory() pour react-router-dom v5.

  useEffect(() => {
    // Lors du montage, nous essayons de nous connecter avec un jeton d'identification Magic dans l'URL.
    magic.auth.loginWithCredential().then(async (didToken) => {
      // Valide le jeton d'authentification avec le serveur
      // const res = await fetch('/api/login', {
      const res = await fetch(`${API_URL}/api/magic/magic-login`, {
        
        // method: 'POST', // Assurez-vous que la méthode est correcte pour votre endpoint d'API.
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + didToken,
          'x-api-key': `${API_KEY_WEALTHTECH}`,

        },
      });
      console.log("res=>",res)
      if (res.status === 200) {
        navigate('/profil/dashboard/');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    });
  }, [navigate]); // Ajoutez navigate dans le tableau de dépendances

  return <Loading />;
}
