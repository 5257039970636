import { useEffect } from "react";

const PageScrollTop = ({ children }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []); // Ajout des dépendances vides pour s'assurer que l'effet ne s'exécute qu'une fois au montage

    return children;
};

export default PageScrollTop;
