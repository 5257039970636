import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { FiArrowRight } from "react-icons/fi";

 
                    
                    
                    
                    
const testimonialData =[{
    id: 1,
    title: "Sport",
    detail: `Sportifs professionnel, Equipes, Fédérations, Clubs sportifs, Associations de supporters `,
    img: "images/wti/new/application-blockchain/sport-1.jpg",
    url:"/cas-application-blockchain/sport"
  }, {
    id: 2,
    title: "Culture",
    detail: `
        Artistes,
        Collectionneurs,
        producteurs,
        Cinéastes,
        Acteurs,
        Art numérique
                `,
    img: "images/wti/new/application-blockchain/culture-1.jpg",
    url:"/cas-application-blockchain/culture"
  }, { 
    id: 3,
    title: "Santé",
    detail: `
              Ordre des médecins,
              Mutuelles complémentaires,
              Assurance maladie,
              Dossier médicale
              `,
    img: "images/wti/new/application-blockchain/sante-1.jpg",
    url:"/cas-application-blockchain/sante"
  }, {
    id: 4,
    title: "Administration publique",
    detail: `
              Etat Civil,
              Cadastre,
              Marché public,
              Elections
                `,
    img: "images/wti/new/application-blockchain/administration-1.jpg",
    url:"/cas-application-blockchain/administration-publique"
  }, {
    id: 5,
    title: "Agro-industrie/Agriculture",
    detail: `
              Traçabilité des aliments,
              Conformité des cultures,
              Production
      `,
    img: "images/wti/new/application-blockchain/agriculture-1.jpg",
    url:"/cas-application-blockchain/agro-industrie-agriculture"
  }, {
    id: 6,
    title: "Education",
    detail: `
              Diplômes et relevés de notes
              Grandes écoles
              Universités
              Collèges et lycées
              `,
    img: "images/wti/new/application-blockchain/education-1.jpg",
    url:"/cas-application-blockchain/education"
  }, 

  {
    id: 9,
    title: "Hôtellerie",
    detail: `Innover en gérant les réservations et la fidélité via blockchain.
                `,
    img: "images/wti/new/application-blockchain/hotellerie-1.jpg",
    url:"/cas-application-blockchain/hotellerie"
  }, {
    id: 10,
    title: "Assurance",
    detail: `Automatisation des contrats d'assurance grâce à la blockchain.

                `,
    img: "images/wti/new/application-blockchain/assurance-1.jpg",
    url:"/cas-application-blockchain/assurance"
  }, {
    id: 11,
    title: "Média et publicité",
    detail: `Transparence des publicités et droits d'auteur avec la blockchain.

                `,
    img: "images/wti/new/application-blockchain/media-1.jpg",
    url:"/cas-application-blockchain/media-publicite"
  }, {
    id: 12,
    title: "Banque et Finance",
    detail: `Renforcer la sécurité et l'efficacité des transactions financières.

                `,
    img: "images/wti/new/application-blockchain/banque-1.jpg",
    url:"/cas-application-blockchain/banque-finance"
  }, {
    id: 12,
    title: "Supply Chain",
    detail: `Optimiser la traçabilité et l'efficacité de la chaîne d'approvisionnement.

                `,
    img: "images/wti/new/application-blockchain/supply-1.jpg",
    url:"/cas-application-blockchain/supply-chain"
}]

                    
                    
                    
                    
/**
 * `BlockchainBlogsApplication` est un composant React qui affiche une liste de blogs ou d'articles
 * sur des sujets liés à la blockchain. Chaque élément de la liste est présenté avec une image, un titre,
 * et un résumé ou des détails supplémentaires. Le composant utilise `ScrollAnimation` pour animer
 * l'entrée des éléments de blog lors du défilement de l'utilisateur.
 *
 * @param {Object} props Les propriétés passées au composant `BlockchainBlogsApplication`.
 * @param {string} props.column La classe de colonne Bootstrap pour contrôler la largeur de chaque élément de blog
 *                              dans la grille, par exemple "col-lg-4 col-md-6 col-sm-6 col-12".
 * @param {string} props.StyleVar Variations de style supplémentaires pour personnaliser l'apparence des cartes de blog.
 *
 * @returns {React.Component} Un composant React qui rend une grille de blogs/articles sur la blockchain.
 *
 * Exemple d'utilisation :
 * ```jsx
 * <BlockchainBlogsApplication
 *   column="col-lg-4 col-md-6 col-sm-6 col-12"
 *   StyleVar="card-style-default testimonial-style-one style-two"
 * />
 * ```
 *
 * Assurez-vous que les images référencées dans `testimonialData` sont accessibles et que
 * `react-animate-on-scroll` ainsi que `react-icons/fi` sont correctement installés dans votre projet.
 */
const BlockchainBlogsApplication = ({column , StyleVar}) => {
    return (
        <div className="row row--15">
            {testimonialData.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`rn-card ${StyleVar}`}>
                            <div className="inner">
                                <div className="card-image">
                                    <img src={`${data.img}`} alt="Blog Image" />
                                </div>
                                      
                                {/* <figure className="thumbnail card-image">
                                    <img src={`${data.img}`} alt="Corporate React Template" />
                                </figure> */}
                                <figcaption className="content">
                                    <p className="description">{data.detail}</p>
                                    <h2 className="title">{data.title}</h2>
                                    {/* <h6 className="subtitle theme-gradient">{data.designation}</h6> */}
                                    <div className="call-to-btn text-center mt--30">
                                        <a className="btn-default btn-icon"  href={data.url} >Savoir plus <i className="icon"><FiArrowRight /></i></a>
                                    </div>
                                </figcaption>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}

export default BlockchainBlogsApplication;
