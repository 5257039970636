import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import {Link} from "react-router-dom";
import { FiArrowRight, FiCheck } from "react-icons/fi";

const BlockchainCaracteristique = () => {
    return (
        <div className="rwt-about-area about-style-2 ">
            <div className="container">
                {/* <h4 className="titl4 mt--10 text-center">Qui sommes-nous?</h4> */}
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <h2 className="titl4 mt--10 text-center">Caractéristiques</h2>

                    </ScrollAnimation>

                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <p>
                            En pratique, une blockchain est une base de données qui contient l’historique de tous les échanges effectués entre ses utilisateurs depuis sa création. Les principales caractéristiques sont :
                        </p>
                    </ScrollAnimation>

                <div className="row row--30 align-items-center">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100"  src="/images/wti/new/blockchain/blockchain-2.jpg" alt="About Images" />
                        </div>
                    </div>

                    
                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">
                                

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> L’identification de chaque partie s’effectue par un procédé cryptographique.</li>
                                        <li><span className="icon"><FiCheck /></span> La transaction est envoyée à un réseau (ou « nœud » de stockage) d’ordinateurs situés dans le monde entier (participants au réseau)</li>
                                        <li><span className="icon"><FiCheck /></span> Chaque « nœud » héberge une copie de la base de données. L’historique de toutes les transactions effectuées est inscrit dans cette base de données qui est simultanément accessible et consultable par tous les participants du réseau.</li>
                                        <li><span className="icon"><FiCheck /></span> Le consensus de validation sécurise le réseau. Le mécanisme de consensus définit les règles qui permettent de valider et d’ajouter des informations sur tous les « nœuds ». Avant l’ajout des informations aux « nœuds », les « mineurs » ou « validateurs » déchiffrent et authentifient les données puis valident les transactions qui sont ensuite ajoutées dans la base de données sous forme d’un bloc de données chiffrées (c’est le « block » dans blockchain).</li>
                                        <li><span className="icon"><FiCheck /></span> La chaîne de blocs : Chaque nouveau bloc ajouté à la blockchain est lié au précédent et une copie est transmise à tous les « nœuds » du réseau. L’intégration de ces blocs est chronologique et les informations qu’ils contiennent sont indélébiles et infalsifiables.</li>
                                    </ul>
                                </ScrollAnimation>
                                {/* <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>
                                        Suite au constat d’absence d’informations et de formations sur le sujet de la blockchain en Afrique, nous avons décidé de mettre en place une structure pour promouvoir cette nouvelle technologie et les avantages liés à son implémentation.
                                        Nous avons notamment mis en place des partenariats avec des universités et des associations dans l'optique d'informer, de former et de concevoir des projets en ce sens.
                                    </p>
                                </ScrollAnimation> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default BlockchainCaracteristique;
