import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FiArrowRight, FiCheck } from "react-icons/fi";

// Pour le Carousel slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Carousel } from 'bootstrap';

/**
 * Liste des image qui defillent.
 *
 * @type {Object[]}
 */
 const list = [
    {
        id: 1,
        image: '/images/wti/new/sante/sante-1.jpg',
    },
    {
        id: 2,
        image: '/images/wti/new/sante/sante-2.jpg',
    },
    {
        id: 3,
        image: '/images/wti/new/sante/sante-3.jpg',
    },
    {
        id: 4,
        image: '/images/wti/new/sante/sante-4.jpg',
    },
    {
        id: 5,
        image: '/images/wti/new/sante/sante-5.jpg',
    },
 ]

const CasSante = () => {
    // Paramètres pour le carrousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: "linear"
    };
    return (
        <div className="rwt-about-area about-style-2 ">
            <div className="container">
                <div className="row row--30 align-items-center mt-5">
                    <div className="col-lg-5">
                    <Slider {...settings}>
                            {list?.map((item, index) => (
                        <div key={index} className="thumbnail">
                            <img className="w-100" src={item.image} alt={`Additional ${index}`}/>
                        </div>
                            ))}
                    </Slider>

                    </div>

                    
                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                <ul className="list-iconCasApp">
                                    <li><span className="icon"><FiCheck /></span><b>Sécurité et confidentialité des données :</b> La blockchain assure la protection des données de santé, garantissant leur confidentialité et intégrité.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Amélioration de la coordination des soins :</b> Grâce à un accès sécurisé aux dossiers médicaux, la blockchain facilite une meilleure coordination entre les professionnels de santé.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Lutte contre la contrefaçon de médicaments :</b> Elle permet un suivi transparent de la chaîne d'approvisionnement des médicaments, assurant leur authenticité et qualité.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Automatisation des processus administratifs :</b> La blockchain réduit les erreurs et fraudes dans la facturation et les paiements, améliorant l'efficacité administrative.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Défis réglementaires et d'interopérabilité :</b> L'intégration de la blockchain dans la santé nécessite de surmonter des défis réglementaires et techniques importants.</li>
                                </ul>




 
 

                                
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default CasSante;
