import React, { useEffect, useRef } from 'react';
import {Typed} from 'react-typed';
import ScrollAnimation from 'react-animate-on-scroll';
import SEO from "../common/SEO";
import HeaderTwo from '../common/header/HeaderTwo';
import {Link} from "react-router-dom";
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import Separator from "../elements/separator/Separator";

// Pour le Carousel slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Carousel } from 'bootstrap';

/**
 * Liste des Conseils et dévéloppement en blockcahin avec des détails pour chacun.
 *
 * @type {Object[]}
 */
const ConseilsList = [
    { id: 1, img: '/images/wti/new/conseils-blockchain/conseil-1.jpg', title: 'Consulting : Etudes et conseils stratégiques', description: "Exploration des cas d’usage, études, analyse. Nos expertises sont au service de vos idées et de votre entreprise pour vous permettre d'imaginer de nouveaux services et d'assurer une implémentation réussie de la blockchain dans votre activité."},
    { id: 2, img: '/images/wti/new/conseils-blockchain/conseil-2.jpg', title: 'Analyse et optimisation des projets', description: "Nous étudions en détail votre projet et vous proposons des plans fonctionnels et techniques. Nous analysons votre projet afin de réduire les risques, les coûts associés et garantir une utilisation optimale de votre blockchain."},
    { id: 3, img: '/images/wti/new/conseils-blockchain/conseil-3.jpg', title: 'Développement et implémentation de solutions sur-mesure', description: "Notre équipe de developpeurs et de consultants « métiers » met tout en œuvre pour assurer la bonne réalisation et l’implémentation de vos projets sur-mesure, de l’expression du besoin à la réalisation du POC, la mise en production et la livraison de votre solution."},
    { id: 4, img: '/images/wti/new/conseils-blockchain/conseil-4.jpg', title: 'Audits et diligences techniques et règlementaires', description: "Nous auditons vos projets ou vos solutions blockchain existantes, sur le plan technique, juridique et de gouvernance. Nos analyses sont suivies de recommandations en vue d’une amélioration ou d’un accompagnement dans le cadre d’un partenariat stratégique."},
]
   




/**
 * Page des détails des Conseils et dévéloppement en blockcahin.
 *
 * @component
 * @returns {JSX.Element} Composant React
 */
const ConseilsDeveloppement = () => {
    // Variable de l'url du frontend
    const API_URL_FRONTEND =process.env.REACT_APP_API_URL_FRONTEND;
    const typedTarget = useRef(null);

   
    /**
     * useEffect qui fait défiler les mots
     *
     * @type {Object[]}
     */
    useEffect(() => {
        const options = {
            
            strings: ["Etudes et conseils", "Analyse des projets", "Développement de solutions", "Diligences techniques"],
            typeSpeed: 80,
            backSpeed: 5,
            backDelay: 1000,
            loop: true,
        };

        const typed = new Typed(typedTarget.current, options);

        return () => {
            typed.destroy();
        };
    }, []);

    // Paramètres pour le carrousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: "linear"
    };


    
    return (
        <>
            <SEO
                title="Conseils et Développement en Blockchain || Wealthtech Innovations"
                description="Accélérez votre transition vers la blockchain avec les conseils et solutions de développement sur mesure de Wealthtech Innovations. Du consulting stratégique à l'implémentation technique, découvrez comment transformer votre activité grâce à la blockchain."
                canonicalUrl={`https://www.wealthtechinnovations.com/conseils-developpement`}
                ogTitle="Expertise en Conseils et Développement Blockchain - Wealthtech Innovations"
                ogDescription="Transformez votre entreprise avec les stratégies blockchain de Wealthtech Innovations. Bénéficiez de notre accompagnement personnalisé pour exploiter pleinement le potentiel de la blockchain."
                ogUrl={`https://www.wealthtechinnovations.com/conseils-developpement`}
            />

            <main className="page-wrapper">
                <HeaderTwo btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 variation-default height-650 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/wti/new/conseils-blockchain/conseilBg-2.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center py-5">
                                    <h2 className="title display-one theme-gradient color-white">Conseils et developpement.<br/>
                                    <span ref={typedTarget}></span>
                                    </h2>
                                    
                                    {/* <p className="description">Nous guidons l'exploration des technologies blockchain, proposant des solutions pour mieux saisir les enjeux des actifs numériques.</p> */}
                                    <div className="button-group">
                                        <Link className="btn-default btn-medium round btn-icon" to="/nos-offres">Nos offres</Link>
                                        {/* <Link className="btn-default btn-medium btn-border round btn-icon" to="/contact">Contactez-nous <i className="icon"></i></Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />
                {/* <AboutTwo data={data[0]}/> */}

                {/* LES CONFERENCES ICI */}
                {ConseilsList.map((item, index) => (
                <div key={index} className="rwt-about-area about-style-2 mt-5">
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="col-lg-5">
                                <div className="thumbnail">
                                    <img className="w-100" src={ item.img} alt="About Images" />
                                </div>
                            </div>

                            <div className="col-lg-7 mt_md--30 mt_sm--30">
                                <div className="content">
                                    <div className="section-title">

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <h4 className="subtitle"><span className="theme-gradient">Détails.</span></h4>
                                            <h2 className="title mt--10">{item.title}</h2>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <p>{item.description}</p>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>   
                </div>
            ))}
                {/* FIN */}
                
                <Separator />
                

                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default ConseilsDeveloppement
