import React, { useState, useEffect } from "react";
import { FiCopy } from "react-icons/fi";
import Web3 from "web3";
import { magic } from "../../magic"; // Assurez-vous que le chemin d'importation est correct
import ABI_TOKEN_EWARI from "../contracts/abi/AbiStablecoin.json"; // Assurez-vous que le chemin d'importation est correct

const ProfilDashboard = () => {
    // Addresse du stablecoin
const ADDRESS_CONTRAT_EWARI =process.env.REACT_APP_ADDRESS_CONTRAT_EWARI

  const [magicCurrentAddress, setMagicCurrentAddress] = useState("");
  const [formatMagicCurrentAddress, setFormatMagicCurrentAddress] = useState("");
  const [contractStablecoin, setContractStablecoin] = useState(null);
  const [nameStablecoin, setNameStablecoin] = useState("");
  const [symbolStablecoin, setSymbolStablecoin] = useState("");
  const [balanceStablecoin, setBalanceStablecoin] = useState("");



 //***************************************************************** *
        // LES STATES DU STABLECOIN
    // ******************************************************************
    const [userMetadata, setUserMetadata] = useState("...");
    // const [magicCurrentAddress, setMagicCurrentAddress] = useState();
    const [provider, setProvider] = useState(null);
    const [signer, setSigner] = useState();
    const [isLoggingIn, setIsLoggingIn] = useState(false);

    // const [magicCurrentAddress, setMagicCurrentAddress] = useState("...");
    // const [contractStablecoin, setContractStablecoin] = useState();
    // const [nameStablecoin, setNameStablecoin] = useState();
    // const [symbolStablecoin, setSymbolStablecoin] = useState();
    // const [balanceStablecoin, setBalanceStablecoin] = useState();
    const [decimalStablecoin, setDecimalStablecoin] = useState();
   
 
    const [web3, setWeb3] = useState(null); // Modifiez pour utiliser Web3

  // Initialisez Web3 et le contrat au démarrage
  useEffect(() => {
    if (magic) {
        const web3Instance = new Web3(magic.rpcProvider); // Initialisez Web3 avec le fournisseur de Magic
        setWeb3(web3Instance);

        const contract = new web3Instance.eth.Contract(
            ABI_TOKEN_EWARI.abi,
            ADDRESS_CONTRAT_EWARI
        );
        setContractStablecoin(contract);
    }
}, [magic]);

useEffect(() => {
    const getMagicAndWeb3Info = async () => {
        if (magic && web3) {
            const userMetadatas = await magic.user.getMetadata();
            setUserMetadata(userMetadatas);

            const accounts = await web3.eth.getAccounts();
            if (accounts.length > 0) {
                setMagicCurrentAddress(accounts[0]);

                const formattedAddress = formatAddress(accounts[0]);
                setFormatMagicCurrentAddress(formattedAddress)
            
            // Supposons que vous ayez une fonction pour obtenir le solde
            try {
                const nameStablecoin = await contractStablecoin.methods.name().call();
                const symbolStablecoin = await contractStablecoin.methods.symbol().call();
                const decimalStablecoin = Number(await contractStablecoin.methods.decimals().call());
                const balanceStablecoin = await contractStablecoin.methods.balanceOf(accounts[0]).call();
                
                // Conversion du solde avec gestion des décimales
                const formattedBalance = web3.utils.fromWei(balanceStablecoin, 'ether');
                
                // Mise à jour de l'état
                setNameStablecoin(nameStablecoin);
                setSymbolStablecoin(symbolStablecoin);
                setDecimalStablecoin(decimalStablecoin);
                setBalanceStablecoin(formattedBalance);
            } catch (error) {
                console.error("Erreur lors de la récupération des informations du contrat :", error);
            }
            
            }
        }
    };

    getMagicAndWeb3Info();
}, [web3, magic, contractStablecoin]);


    // Fonction pour copier l'adresse 
    const handleCopyAddress = () => {
        navigator.clipboard.writeText(magicCurrentAddress);
        alert("Adresse copiée !");
    };

    // Fonction pour formater l'adresse 
    const formatAddress = (address) => {
        // Vérifier si l'adresse est définie et a la longueur attendue
        if(address && address.length > 20) {
          return `${address.slice(0, 10)}...${address.slice(-10)}`;
        }
        return address; // Retourner l'adresse non modifiée si elle ne correspond pas aux critères
      };

  /**
     * Formate un nombre en tronquant à deux décimales et en ajoutant un séparateur de milliers (espace).
     * @param {number} number - Le nombre à formater.
     * @returns {string} - Le nombre formaté en tant que chaîne de caractères.
     * @throws {Error} - Si la fonction est appelée avec autre chose qu'un nombre.
     */
   function formatNumber(number) {
    const decimalNumber = parseFloat(number);
    if (typeof decimalNumber !== 'number') {
        throw new Error('La fonction doit être appelée avec un nombre.');
    }

    // Tronquer le nombre à deux décimales
    const truncatedNumber = Math.floor(decimalNumber * 100) / 100;

    // Ajouter un séparateur de milliers (espace)
    const formattedNumber = truncatedNumber.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    return formattedNumber;
}

  return (
    <div className="user-profile-container" style={{ backgroundColor: 'black', color: 'white', padding: '20px' }}>
            
            {/* <div className="profile-balance">
                <h2>Solde: {userProfile.balance}</h2>
            </div> */}
            <div className="row">
                <div className="col-lg-6 col-md-6 mt-3">
                    <div className="blockchain-address">
                        <h5 className="theme-gradient">{nameStablecoin}</h5>
                        <p>Solde: {balanceStablecoin} {symbolStablecoin}</p>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 mt-3">
                <div className="blockchain-address">
                    <h5 className="theme-gradient">Adresse Blockchain <FiCopy onClick={handleCopyAddress}/></h5>
                    <p>{formatMagicCurrentAddress}</p>
                    {/* <button onClick={handleCopyAddress}><FiCopy /> Copier l'adresse</button> */}
                </div>
                </div>
            </div>
        </div>
  );
};

export default ProfilDashboard;
