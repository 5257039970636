import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FiArrowRight, FiCheck } from "react-icons/fi";
import BlockchainBlogsApplication from './BlockchainBlogsApplication';

/**
 * `BlockchainApplication` est un composant React qui sert à présenter des cas d'application de la technologie blockchain
 * dans différents secteurs d'activité. Il affiche une section introductive sur l'importance et les applications concrètes
 * de la blockchain, suivie d'une liste de cas d'application spécifiques au domaine de la blockchain.
 *
 * Ce composant intègre le composant `BlockchainBlogsApplication` pour lister ces cas d'application et utilise 
 * `ScrollAnimation` pour animer l'apparition des sections à l'écran. Il est structuré en plusieurs parties principales,
 * incluant un titre, une introduction à la technologie blockchain, une galerie de cas d'application, et une liste
 * d'exemples concrets d'utilisation de la blockchain dans le monde réel.
 *
 * Aucun prop n'est requis pour utiliser `BlockchainApplication`, car il fonctionne avec des données statiques
 * et du contenu fixe intégré directement dans le composant.
 *
 * Exemple d'utilisation :
 * ```jsx
 * <BlockchainApplication />
 * ```
 *
 * Remarque : Assurez-vous que `react-animate-on-scroll` et `react-icons` sont installés dans votre projet pour
 * que les animations et les icônes fonctionnent correctement.
 */
const BlockchainApplication = () => {
    return (
        <div className="rwt-about-area about-style-2 ">
            <div className="container">
                {/* <h4 className="titl4 mt--10 text-center">Qui sommes-nous?</h4> */}
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <h2 className="titl4 mt--10 text-center">Cas d'application</h2>

                    </ScrollAnimation>


                    {/* Appel de la liste des blogs de cas d'utilisation */}
                    <div className="rwt-testimonial-area ">
                    <div className="container">
                
                        <BlockchainBlogsApplication column="col-lg-4 col-md-6 col-sm-6 col-12 mt--30" teamStyle="card-style-default testimonial-style-one style-two" />
                    </div>
                </div>
                {/* Fin Appel de la liste des blogs de cas d'utilisation */}


                    {/* <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <BlockchainBlogsApplication/>
                    </ScrollAnimation> */}

                <div className="row row--30 align-items-center mt-5">
                    <div className="col-lg-5">
                        <div className="thumbnail">
                            <img className="w-100" src="/images/wti/new/application-blockchain/importance-bc-1.jpg" alt="About Images" />
                        </div>
                    </div>

                    
                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>
                                    La Blockchain vous permet de simplifier la tenue des dossiers et de rationaliser les flux de travail partagés. L'implémentation de cette technologie dans votre organisation vous permettra sûrement d'accélérer votre développement. C'est pourquoi nous vous proposons de découvrir de quelle façon implémenter la blockchain dans votre secteur d'activité. La blockchain a passé le cap de l'évangélisation. Il n'est plus nécessaire de rappeler l'intérêt que représente cette technologie. Les cas d'usage sont infinis et nombre d'entreprises sont passées du stade de POC (proof of concept) aux réalisations bien concrètes. Quelques exemples :
                                    </p>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <ul className="list-icon">
                                        <li><span className="icon"><FiCheck /></span> GE Aviation utilise la blockchain pour simplifier le suivi des pièces des avions, de l’usine au vol.</li>
                                        <li><span className="icon"><FiCheck /></span> Bühler utilise la technologie de blockchain pour suivre les cultures de la ferme à l’assiette, ce qui lui permet de produire chaque jour des aliments sains et sûrs pour deux milliards de personnes.</li>
                                        <li><span className="icon"><FiCheck /></span> Nasdaq apporte aux marchés financiers la technologie blockchain pour gérer l’exécution, le paiement et le règlement des transactions à partir de plusieurs blockchains et mécanismes de paiement.</li>
                                    </ul>
                                </ScrollAnimation>
                                {/* <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <p>
                                        Suite au constat d’absence d’informations et de formations sur le sujet de la blockchain en Afrique, nous avons décidé de mettre en place une structure pour promouvoir cette nouvelle technologie et les avantages liés à son implémentation.
                                        Nous avons notamment mis en place des partenariats avec des universités et des associations dans l'optique d'informer, de former et de concevoir des projets en ce sens.
                                    </p>
                                </ScrollAnimation> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default BlockchainApplication;
