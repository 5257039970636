import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FiArrowRight, FiCheck } from "react-icons/fi";

// Pour le Carousel slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Carousel } from 'bootstrap';

/**
 * Liste des image qui defillent.
 *
 * @type {Object[]}
 */
 const list = [
    {
        id: 1,
        image: '/images/wti/new/banque/banque-1.jpg',
    },
    {
        id: 2,
        image: '/images/wti/new/banque/banque-2.jpg',
    },
    {
        id: 3,
        image: '/images/wti/new/banque/banque-3.jpg',
    },
    {
        id: 4,
        image: '/images/wti/new/banque/banque-4.jpg',
    },
    {
        id: 5,
        image: '/images/wti/new/banque/banque-5.jpg',
    },
 ]

const CasBanqueFinance = () => {
    // Paramètres pour le carrousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: "linear"
    };
    return (
        <div className="rwt-about-area about-style-2 ">
            <div className="container">
                <div className="row row--30 align-items-center mt-5">
                    <div className="col-lg-5">
                    <Slider {...settings}>
                            {list?.map((item, index) => (
                        <div key={index} className="thumbnail">
                            <img className="w-100" src={item.image} alt={`Additional ${index}`}/>
                        </div>
                            ))}
                    </Slider>

                    </div>

                    
                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                <ul className="list-iconCasApp">
                                    <li><span className="icon"><FiCheck /></span><b>Transactions accélérées et coûts réduits :</b> La blockchain facilite des transactions plus rapides et moins coûteuses, optimisant les opérations bancaires.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Renforcement de la sécurité :</b> Elle améliore la sécurité des données financières, réduisant les risques de fraude et de cyberattaques.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Conformité réglementaire :</b> La blockchain offre une meilleure traçabilité des transactions, facilitant la conformité aux normes réglementaires.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Innovation dans les produits financiers :</b> Elle permet le développement de nouveaux produits financiers comme les cryptomonnaies et les smart contracts.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Défis d'intégration :</b> L'intégration de la blockchain dans la finance est confrontée à des obstacles techniques et réglementaires majeurs. La coopération entre l'industrie et les régulateurs est cruciale pour relever ces défis et garantir le succès de cette transition.</li>
                                </ul>

                                
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default CasBanqueFinance;
