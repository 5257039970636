import React, { useEffect, useRef } from 'react';
import {Typed} from 'react-typed';
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import HeaderTwo from '../common/header/HeaderTwo';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import Separator from "../elements/separator/Separator";
import BlockchainLexique from '../components/wti/blockchain/BlockchainLexique';



/**
 * Page du Lexique de la Blockchain.
 * 
 * Ce composant affiche le lexique de la blockchain, fournissant des définitions clés
 * et des explications sur des termes techniques liés à la blockchain et aux crypto-actifs.
 * L'objectif est d'éduquer les utilisateurs sur le vocabulaire de la blockchain
 * et de faciliter une meilleure compréhension de cette technologie.
 *
 * @component
 * @example
 * return (
 *   <Lexique />
 * )
 */
const Lexique = () => {
    // Variable de l'url du frontend
    const API_URL_FRONTEND =process.env.REACT_APP_API_URL_FRONTEND;
    const typedTarget = useRef(null);

    useEffect(() => {
        const options = {
            strings: ["Le lexique."],
            typeSpeed: 80,
            backSpeed: 5,
            backDelay: 1000,
            loop: true,
        };

        const typed = new Typed(typedTarget.current, options);

        return () => {
            typed.destroy();
        };
    }, []);

    return (
        <>
            <SEO
                title="Lexique de la Blockchain - Définitions Clés Expliquées par Wealthtech Innovations"
                description="Explorez notre Lexique de la Blockchain pour découvrir les définitions clés et concepts essentiels autour de la technologie blockchain. Wealthtech Innovations vous guide à travers le jargon pour une meilleure compréhension du monde des crypto-actifs et de la technologie de registre distribué."
                canonicalUrl={`https://www.wealthtechinnovations.com/lexique`}
                ogTitle="Découvrez le Lexique de la Blockchain par Wealthtech Innovations"
                ogDescription="Maîtrisez le vocabulaire de la blockchain avec le lexique complet offert par Wealthtech Innovations. Des termes techniques aux concepts de base, enrichissez votre compréhension de la blockchain."
                ogUrl={`https://www.wealthtechinnovations.com/lexique`}
            />

            <main className="page-wrapper">
                <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" />

                 {/* Start Slider Area  */}

                <div className="slider-area slider-style-1 bg-transparent py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <h1 className="title theme-gradient display-two">Technologie Blockchain <br />
                                        <span ref={typedTarget}></span>
                                    </h1>
                                    <p className="description">Le lexique de la blockchain définit les aspects clés de cette technologie de registre distribué.</p>
                                    <div className="button-group">
                                        {/* <a className="btn-default btn-medium round btn-icon" target="_blank" href="https://themeforest.net/checkout/from_item/33571911?license=regular">Vers nos missions<i className="icon"><FiArrowRight /></i></a> */}
                                        <Link className="btn-default btn-medium round btn-icon" to="/cas-application">Cas d'application</Link>
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="/blockchain">Vers Blockchain <i className="icon"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />                            

                {/* Partie blockcain lexique */}
                <br/><br/>
                <BlockchainLexique/>
                {/* Fin partie blockcain lexique */}

                {/* Start Call To Action Area  */}
                <div className="rwt-callto-action-area">
                    <div className="wrapper">
                        {/* <CalltoActionFive /> */}
                    </div>
                </div>
                {/* End Call To Action Area  */}
                

                <Separator />                               
               
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default Lexique;