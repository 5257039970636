import React, { useEffect, useRef } from 'react';
import {Typed} from 'react-typed';
import ScrollAnimation from 'react-animate-on-scroll';
import SEO from "../common/SEO";
import HeaderTwo from '../common/header/HeaderTwo';
import {Link} from "react-router-dom";
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import Separator from "../elements/separator/Separator";

// Pour le Carousel slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Carousel } from 'bootstrap';

/**
 * Liste des Conférence et formation blockchain avec des détails pour chacun.
 *
 * @type {Object[]}
 */
const ConferenceList = [
    { id: 1, img: '/images/wti/new/conference-blockchain/technique-1.jpg', title: 'Formations techniques', description: 'Pour les développeurs, nous proposons des formations techniques qui leur permettront de concevoir des architectures blockchains spécifiques et adaptées au domaine d’activité.'},
    { id: 2, img: '/images/wti/new/conference-blockchain/comex-1.jpg', title: 'Interventions COMEX', description: 'Nous intervenons auprès de vos dirigeants afin de leur donner une vision stratégique de l’utilité des blockchains et cryptoactifs.'},
    { id: 3, img: '/images/wti/new/conference-blockchain/metier-1.jpg', title: 'Interventions métiers réglementés', description: 'Nous intervenons auprès des professions règlementées dans l’optique de proposer des solutions basées sur les blockchains et actifs numériques : « specific use cases ».'},
    { id: 4, img: '/images/wti/new/conference-blockchain/institution-1.jpg', title: 'Interventions Institutions publiques et privée', description: 'Nous intervenons auprès des institutions publiques et privées en tant que consultants ou formateurs afin de donner une vision stratégique de l’utilité des blockchains et cryptoactifs. Notre intervention peut s’étendre à la réflexion ou la réalisation de projet concrets.'},
    { id: 5, img: '/images/wti/new/conference-blockchain/public-1.jpg', title: 'Conférences tout public', description: 'Nous proposons des conférences en ligne grand public pour acculturer les particuliers, les équipes et les métiers aux impacts et avantages des blockchains et cryptoactifs.'},
    // Ajoutez 3 autres objets pour les images supplémentaires
]
   




/**
 * Page des détails des Conférences et formations blockchain.
 *
 * @component
 * @returns {JSX.Element} Composant React
 */
const ConferenceFormationsBlockchain = () => {
    // Variable de l'url du frontend
    const API_URL_FRONTEND =process.env.REACT_APP_API_URL_FRONTEND;
    const typedTarget = useRef(null);


    /**
     * useEffect qui fait défiler les mots
     *
     * @type {Object[]}
     */
    useEffect(() => {
        const options = {
            
            strings: ["Formations techniques", "Interventions COMEX", "Interventions métiers ", "Interventions publiques ", "Conférences tout public"],
            typeSpeed: 80,
            backSpeed: 5,
            backDelay: 1000,
            loop: true,
        };

        const typed = new Typed(typedTarget.current, options);

        return () => {
            typed.destroy();
        };
    }, []);

    
    return (
        <>
            <SEO
                title="Conférences et Formations Blockchain par Wealthtech Innovations"
                description="Wealthtech Innovations propose des conférences et des formations approfondies sur la blockchain, destinées à un public varié, des développeurs aux dirigeants. Découvrez comment la blockchain peut transformer votre secteur."
                canonicalUrl={`https://www.wealthtechinnovations.com/conference-formations-blockchain`}
                ogTitle="Transformez votre Avenir avec les Conférences et Formations Blockchain de Wealthtech Innovations"
                ogDescription="Plongez dans l'univers de la blockchain avec les experts de Wealthtech Innovations. Que vous soyez un professionnel cherchant à se spécialiser ou un dirigeant souhaitant comprendre les enjeux stratégiques, nos conférences et formations sont conçues pour vous."
                ogUrl={`https://www.wealthtechinnovations.com/conference-formations-blockchain`}
            />
            <main className="page-wrapper">
                <HeaderTwo btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 variation-default height-650 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/wti/new/conference-blockchain/conferenceBg-2.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <h2 className="title display-one theme-gradient color-white">Conference et formations blockchain.<br/>
                                    <span ref={typedTarget}></span>
                                    </h2>
                                    
                                    {/* <p className="description">Nous guidons l'exploration des technologies blockchain, proposant des solutions pour mieux saisir les enjeux des actifs numériques.</p> */}
                                    <div className="button-group">
                                        <Link className="btn-default btn-medium round btn-icon" to="/nos-offres">Nos offres</Link>
                                        {/* <Link className="btn-default btn-medium btn-border round btn-icon" to="/contact">Contactez-nous <i className="icon"></i></Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />
                {/* <AboutTwo data={data[0]}/> */}

                {/* LES CONFERENCES ICI */}
                {ConferenceList.map((item, index) => (
                <div key={index} className="rwt-about-area about-style-2 mt-5">
                    <div className="container">
                        <div className="row row--30 align-items-center">
                            <div className="col-lg-5">
                                <div className="thumbnail">
                                    <img className="w-100" src={item.img} alt="About Images" />
                                </div>
                            </div>

                            <div className="col-lg-7 mt_md--30 mt_sm--30">
                                <div className="content">
                                    <div className="section-title">

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <h4 className="subtitle"><span className="theme-gradient">Détails.</span></h4>
                                            <h2 className="title mt--10">{item.title}</h2>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <p>{item.description}</p>
                                        </ScrollAnimation>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>   
                </div>
            ))}
                {/* FIN */}
                
                <Separator />
                

                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default ConferenceFormationsBlockchain
