import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FiArrowRight, FiCheck } from "react-icons/fi";

// Pour le Carousel slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Carousel } from 'bootstrap';

/**
 * Liste des image qui defillent.
 *
 * @type {Object[]}
 */
 const list = [
    {
        id: 1,
        image: '/images/wti/new/administration/administration-1.jpg',
    },
    {
        id: 2,
        image: '/images/wti/new/administration/administration-2.jpg',
    },
    {
        id: 3,
        image: '/images/wti/new/administration/administration-3.jpg',
    },
    {
        id: 4,
        image: '/images/wti/new/administration/administration-4.jpg',
    },
    {
        id: 5,
        image: '/images/wti/new/administration/administration-5.jpg',
    },
 ]

const CasAdministrationPublique = () => {
    // Paramètres pour le carrousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: "linear"
    };
    return (
        <div className="rwt-about-area about-style-2 ">
            <div className="container">
                <div className="row row--30 align-items-center mt-5">
                    <div className="col-lg-5">
                    <Slider {...settings}>
                            {list?.map((item, index) => (
                        <div key={index} className="thumbnail">
                            <img className="w-100" src={item.image} alt={`Additional ${index}`}/>
                        </div>
                            ))}
                    </Slider>

                    </div>

                    
                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                <ul className="list-iconCasApp">
                                    <li><span className="icon"><FiCheck /></span><b>Transparence accrue : </b> Avec la blockchain, l'enregistrement des transactions devient transparent et permanent, renforçant la confiance des citoyens dans l'administration.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Sécurité renforcée : </b> La blockchain crypte et distribue les données, réduisant les risques de piratage et de manipulation pour une sécurité optimale.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Efficacité améliorée : </b> En automatisant les processus et en réduisant la paperasse, la blockchain accélère les services publics, générant des économies de temps et d'argent.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Réduction de la fraude : </b> La blockchain, par sa vérifiabilité, aide à prévenir la fraude et les erreurs, améliorant l'intégrité des opérations administratives.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Défis d'intégration : </b> L'adoption de la blockchain présente des défis réglementaires et techniques, nécessitant des ajustements dans les processus administratifs existants.</li>
                                </ul>

                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default CasAdministrationPublique;
