import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FiArrowRight, FiCheck } from "react-icons/fi";

// Pour le Carousel slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Carousel } from 'bootstrap';

/**
 * Liste des image qui defillent.
 *
 * @type {Object[]}
 */
 const list = [
    {
        id: 1,
        image: '/images/wti/new/education/education-1.jpg',
    },
    {
        id: 2,
        image: '/images/wti/new/education/education-2.jpg',
    },
    {
        id: 3,
        image: '/images/wti/new/education/education-3.jpg',
    },
    {
        id: 4,
        image: '/images/wti/new/education/education-4.jpg',
    },
    {
        id: 5,
        image: '/images/wti/new/education/education-5.jpg',
    },
 ]

const CasEducation = () => {
    // Paramètres pour le carrousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: "linear"
    };
    return (
        <div className="rwt-about-area about-style-2 ">
            <div className="container">
                <div className="row row--30 align-items-center mt-5">
                    <div className="col-lg-5">
                    <Slider {...settings}>
                            {list?.map((item, index) => (
                        <div key={index} className="thumbnail">
                            <img className="w-100" src={item.image} alt={`Additional ${index}`}/>
                        </div>
                            ))}
                    </Slider>

                    </div>

                    
                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                <ul className="list-iconCasApp">
                                    <li><span className="icon"><FiCheck /></span><b>Gestion sécurisée des dossiers :</b> La blockchain propose une méthode robuste de stockage et de partage des dossiers éducatifs. En garantissant la sécurité, la transparence et l'intégrité des données, elle facilite l'accès et la validation des qualifications, renforçant ainsi la confiance dans les processus éducatifs et professionnels.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Automatisation de la vérification :</b> Elle simplifie et accélère la vérification des qualifications académiques, réduisant les coûts.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Reconnaissance internationale :</b> La technologie facilite la mobilité étudiante et la reconnaissance globale des diplômes.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Apprentissage personnalisé :</b> La blockchain permet de développer des modèles éducatifs adaptés à chaque étudiant.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Défis d'intégration :</b> L'implémentation de la blockchain en éducation exige de surmonter des obstacles techniques et réglementaires.</li>
                                </ul>
 
 

                                
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default CasEducation;
