import React, { useEffect, useRef } from 'react';
import {Typed} from 'react-typed';
import ScrollAnimation from 'react-animate-on-scroll';
import SEO from "../../common/SEO";
import HeaderTwo from '../../common/header/HeaderTwo';
import {Link} from "react-router-dom";
import FooterTwo from '../../common/footer/FooterTwo';
import Separator from "../../elements/separator/Separator";
import Copyright from '../../common/footer/Copyright';
import CasSupplyChain from '../../components/wti/blockchain/casApplication/CasSupplyChain';





/**
 * Page détaillant l'application de la blockchain dans la chaîne d'approvisionnement.
 *
 * Ce composant illustre comment Wealthtech Innovations applique la blockchain pour transformer 
 * la chaîne d'approvisionnement, mettant en avant les avantages tels que l'amélioration de la transparence,
 * la sécurité renforcée, et l'efficacité opérationnelle. Les visiteurs peuvent découvrir des études de cas
 * et des projets développés pour exploiter le potentiel de la blockchain dans ce secteur crucial.
 *
 * @returns {JSX.Element} Le composant React pour la page dédiée à l'application blockchain dans la supply chain.
 */
const SupplyChain = () => {
    // Variable de l'url du frontend
    const API_URL_FRONTEND =process.env.REACT_APP_API_URL_FRONTEND;
     const typedTarget = useRef(null);


    /**
     * useEffect qui fait défiler les mots
     *
     * @type {Object[]}
     */
    useEffect(() => {
        const options = {
            
            strings: ["Transparence et traçabilité", "Réduction des risques", "Automatisation des accords", "Amélioration de l'efficacité", "Défis d'intégration"],
            typeSpeed: 80,
            backSpeed: 5,
            backDelay: 1000,
            loop: true,
        };

        const typed = new Typed(typedTarget.current, options);

        return () => {
            typed.destroy();
        };
    }, []);

    
    return (
        <>
            <SEO
                title="Blockchain dans la Supply Chain - Solutions par Wealthtech Innovations"
                description="Explorez les cas d'application de la blockchain pour révolutionner la chaîne d'approvisionnement avec Wealthtech Innovations. Découvrez comment notre technologie assure transparence, sécurité et efficacité accrue."
                canonicalUrl={`https://www.wealthtechinnovations.com/cas-application-blockchain/supply-chain`}
                ogTitle="Transformez votre Supply Chain avec la Blockchain - Wealthtech Innovations"
                ogDescription="Chez Wealthtech Innovations, nous utilisons la blockchain pour optimiser la chaîne d'approvisionnement, offrant des solutions pour une gestion transparente, sécurisée et efficiente."
                ogUrl={`https://www.wealthtechinnovations.com/cas-application-blockchain/supply-chain`}
            />

            <main className="page-wrapper">
                <HeaderTwo btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 variation-default height-450 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/wti/new/supply/supplyBg-1.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <h2 className="text-center display-one theme-gradient color-white"> Supply chain<br/>
                                    <span ref={typedTarget}></span>
                                    </h2>
                                    
                                    <p className="descriptionCasApp">
                                        La blockchain révolutionne la gestion de la supply chain en offrant une transparence, une traçabilité et une sécurité sans précédent. Cette technologie permet de documenter de manière immuable chaque étape du parcours d'un produit, de sa fabrication à sa livraison finale. En assurant l'intégrité des données, la blockchain réduit les risques de contrefaçon, de vol et d'erreurs dans la chaîne d'approvisionnement. Elle facilite également la vérification de l'authenticité des produits et l'origine des matières premières, répondant ainsi aux préoccupations croissantes des consommateurs en matière de durabilité et d'éthique. De plus, les contrats intelligents automatisent les paiements et les accords entre les parties, améliorant l'efficacité opérationnelle et réduisant les délais de transaction. Cependant, la mise en œuvre de la blockchain dans la supply chain implique des défis, tels que la nécessité d'une standardisation entre les différents acteurs, la protection des données sensibles et l'intégration avec les systèmes existants. Une collaboration étroite entre les fournisseurs, les fabricants, les distributeurs et les technologues est cruciale pour surmonter ces obstacles et exploiter pleinement le potentiel de la blockchain.
                                    </p>
                                    <div className="button-group text-center mb-3">
                                        <Link className="btn-default btn-medium round btn-icon" to="/cas-application">Cas application</Link>
                                        {/* <Link className="btn-default btn-medium btn-border round btn-icon" to="/contact">Contactez-nous <i className="icon"></i></Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />
                {/* <AboutTwo data={data[0]}/> */}

                {/* contenu */}
                <CasSupplyChain/>
                {/* FIN */}
                
                <Separator />
                

                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default SupplyChain
