import React from 'react';
import { FaSistrix } from "react-icons/fa";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import SideCategories from '../../components/blog/sidebar/SideCategories';
import ProfileInfosUser from '../../components/profil/ProfilInfosUser';


const ProfileIndex = () => {
    return (
        <>
            <SEO title="Profil || dashboard" />
            
            <Layout>
                {/* <BreadcrumbOne 
                    title="The Easiest Way To Create Website <br /> Quick Copy & Make site."
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Blog Grid Sidebar"
                /> */}
                <div className="main-content">
                    {/* Start Blog Area  */}
                    <div className="rn-blog-area mt-5">
                        <div className="container">
                            <div className="row row--30">
                            <div className="col-lg-4 mt_md--40 mt_sm--40">
                                    <aside className="rwt-sidebar">

                                        {/* Start Single Widget  */}
                                        {/* <div className="rbt-single-widget widget_categories mt--40">
                                            <div className="inner">
                                                <SideCategories />
                                            </div>
                                        </div> */}
                                        <SideCategories />
                                        {/* End Single Widget  */}


                                    </aside>
                                </div>

                                <div className="col-lg-8">
                                    <div className="row mt_dec--30">
                                        <ProfileInfosUser/>

                                        {/* <BlogProp column="col-lg-6 col-md-6 col-12 mt--30" /> */}
                                    </div>
                                </div>
                                
                                
                            </div>
                        </div>
                    </div>
                    {/* End Blog Area  */}
                </div>

            </Layout>
        </>
    )
}

export default ProfileIndex










