import React, { useEffect, useRef } from 'react';
import {Typed} from 'react-typed';
import {Link} from "react-router-dom";
import SEO from "../common/SEO";
import HeaderTwo from '../common/header/HeaderTwo';
import Copyright from '../common/footer/Copyright';
import ServiceOne from '../elements/service/ServiceOne';
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import Separator from "../elements/separator/Separator";
import TimelineOne from "../elements/timeline/TimelineOne";
import BlogClassicData from '../data/blog/BlogList.json';
import FooterTwo from '../common/footer/FooterTwo';
var BlogListData = BlogClassicData.slice(0, 3);

/**
 * Composant de la page "Nos Offres" pour Wealthtech Innovations.
 *
 * Ce composant affiche les différentes offres de services proposées par Wealthtech Innovations
 * dans le domaine de la blockchain et des actifs numériques. Il vise à informer les visiteurs
 * sur la gamme de services disponibles pour les aider à comprendre et à adopter ces technologies.
 * 
 * La page comprend une introduction des services via une zone de slider, une section détaillée
 * des services offerts, ainsi qu'une timeline illustrant le processus d'accompagnement proposé
 * par Wealthtech Innovations.
 *
 * @returns {JSX.Element} Le composant React pour la page "Nos Offres".
 */
const NosOffres = () => {
    // Variable de l'url du frontend
    const API_URL_FRONTEND =process.env.REACT_APP_API_URL_FRONTEND;
    const typedTarget = useRef(null);
/**
     * useEffect qui fait défiler les mots
     *
     * @type {Object[]}
     */
    useEffect(() => {
        const options = {
            
            strings: ["Conférences et formations", "Conseils et développement", "Actifs numériques"],
            typeSpeed: 80,
            backSpeed: 5,
            backDelay: 1000,
            loop: true,
        };

        const typed = new Typed(typedTarget.current, options);

        return () => {
            typed.destroy();
        };
    }, []);

    return (
        <>
            <SEO
                title="Nos Offres - Services Blockchain par Wealthtech Innovations"
                description="Découvrez nos offres chez Wealthtech Innovations : des services spécialisés dans la blockchain et les actifs numériques conçus pour guider les entreprises et les individus dans l'adoption de ces technologies révolutionnaires."
                canonicalUrl={`https://www.wealthtechinnovations.com/nos-offres`}
                ogTitle="Explorez Nos Services Blockchain et Crypto-actifs chez Wealthtech Innovations"
                ogDescription="Wealthtech Innovations offre une gamme complète de services pour explorer, comprendre et implémenter les technologies blockchain et les actifs numériques. Découvrez comment nous pouvons transformer votre entreprise."
                ogUrl={`https://www.wealthtechinnovations.com/nos-offres`}
            />

            <main className="page-wrapper">
                <HeaderTwo  btnStyle="btn-small round btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 variation-default height-650 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/wti/new/services/serviceBg-1.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    {/* <h1 className="title display-one">Les services de Wealthtech innovations.</h1> */}
                                    <h2 className="text-center display-one theme-gradient color-white"> Les services de Wealthtech:<br/>
                                    <span ref={typedTarget}></span>
                                    </h2>
                                    <p className="description">Nous guidons l'exploration des technologies blockchain, proposant des solutions pour mieux saisir les enjeux des actifs numériques.</p>
                                    <div className="button-group">
                                        <Link className="btn-default btn-medium round btn-icon" to="/nos-missions">Vers nos missions</Link>
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="/contact">Contactez-nous <i className="icon"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}


                {/* Start Service Area  */}
                <div className="rn-service-area rn-section-gapBottom pt--100">
                    <div className="container">
                        <ServiceOne 
                            serviceStyle = "service__style--1 icon-circle-style"
                            textAlign = "text-center"
                        />
                    </div>
                </div>
                {/* End Service Area  */}


                <Separator />
                {/* Start Timeline Area  */}
                <div className="rwt-timeline-area height-100 rn-section-gapBottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Tout ce qui concerne nos services"
                                        title = "Nos services."
                                        description = "Wealthtech Innovations accompagne les particuliers, les associations, les organisations, les institutions dans la découverte, l’exploration et le déploiement des technologies blockchain. Notre société propose des solutions pour comprendre et mieux appréhender les enjeux induits par les actifs numériques."
                                    />
                            </div>
                        </div>
                        <div className="row mt_dec--20">
                            <div className="col-lg-12">
                                <TimelineOne />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Timeline Area  */}

                

               

                


                <FooterTwo/>
                <Copyright />                    

            </main>
            
        </>
    )
}
export default NosOffres

