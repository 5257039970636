import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FiArrowRight, FiCheck } from "react-icons/fi";

// Pour le Carousel slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Carousel } from 'bootstrap';

/**
 * Liste des image qui defillent.
 *
 * @type {Object[]}
 */
 const list = [
    {
        id: 1,
        image: '/images/wti/new/cultures/culture-1.jpg',
    },
    {
        id: 2,
        image: '/images/wti/new/cultures/culture-2.jpg',
    },
    {
        id: 3,
        image: '/images/wti/new/cultures/culture-3.jpg',
    },
    {
        id: 4,
        image: '/images/wti/new/cultures/culture-4.jpg',
    },
    {
        id: 5,
        image: '/images/wti/new/cultures/culture-5.jpg',
    },
 ]

const CasCulture = () => {
    // Paramètres pour le carrousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: "linear"
    };
    return (
        <div className="rwt-about-area about-style-2 ">
            <div className="container">
                <div className="row row--30 align-items-center mt-5">
                    <div className="col-lg-5">
                    <Slider {...settings}>
                            {list?.map((item, index) => (
                        <div key={index} className="thumbnail">
                            <img className="w-100" src={item.image} alt={`Additional ${index}`}/>
                        </div>
                            ))}
                    </Slider>

                    </div>

                    
                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                <ul className="list-iconCasApp">
                                    <li><span className="icon"><FiCheck /></span><b>Sécurisation des droits :</b> La blockchain assure l'authenticité et la propriété des créations, assurant ainsi une protection et une rémunération équitables aux artistes et créateurs. Cette technologie révolutionnaire élimine les intermédiaires et offre une transparence totale, renforçant la confiance et l'équité dans l'économie de la création.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Transparence et traçabilité :</b> Elle assure une gestion efficace des droits d'auteur, renforçant la lutte contre la contrefaçon culturelle.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Innovation avec les NFTs :</b> La blockchain introduit les NFTs, transformant l'achat, la vente et la collection des œuvres culturelles.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Financement participatif :</b> Elle facilite le soutien direct des communautés aux artistes, favorisant des projets culturels novateurs.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Défis d'intégration :</b> L'adoption de la blockchain dans la culture nécessite de surmonter des obstacles techniques et réglementaires.</li>
                                </ul>
 

                                
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default CasCulture;
