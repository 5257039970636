import React from 'react';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop'

// Pages import Here 
import Accueil from "./pages/Accueil";
import NosMissions from "./pages/NosMissions";
import NosOffres from "./pages/NosOffres";
import NosProjets from "./pages/NosProjets";
import DetailsServices from "./pages/DetailsServices";
import Blockchain from "./pages/Blockchain";
import Lexique from "./pages/Lexique";
// Pages des cas d'applications
import CasApplication from "./pages/CasApplication";
import Sport from './pages/casApplicationBlockchain/Sport';


// Pages d'authentification
import Authentification from './pages/Authentification';

// Pages de magic
import Callback from './pages/magic/Callback';


// Pages du profil
import Dashboard from "./pages/profil/Dashboard";
import ProfileIndex from './pages/profil/ProfileIndex';
import ProfilDashboard from "./components/profil/ProfilDashboard";
import ProfilInfosUser from "./components/profil/ProfilInfosUser";




import HomeDefault from "./pages/HomeDefault";
import BusinessConsulting2 from "./pages/BusinessConsulting2";
import Corporate from "./pages/Corporate";
import Business from "./pages/Business";
import DigitalAgency from "./pages/DigitalAgency";
import Finance from "./pages/Finance";
import Company from "./pages/Company";
import Marketing from "./pages/Marketing";
import TravelAgency from "./pages/TravelAgency";
import Consulting from "./pages/Consulting";
import SeoAgency from "./pages/SeoAgency";
import PersonalPortfolio from "./pages/PersonalPortfolio";
import EventConference from "./pages/EventConference";
import CreativePortfolio from "./pages/CreativePortfolio";
import Freelancer from "./pages/Freelancer";
import InternationalConsulting from "./pages/InternationalConsulting";
import Startup from "./pages/Startup";
import WebAgency from "./pages/WebAgency";


import CategoryList from "./pages/CategoryList";
import TagList from "./pages/TagList";
import Author from "./pages/Author";
import AboutUs from "./pages/AboutUs";

// Elements import Here 

import Elements from "./elements/elements/Elements";
import Button from "./elements/button/Button";
import Service from "./elements/service/Service";
import CounterUp from "./elements/counterup/CounterUp";
import Progressbar from "./elements/progressbar/Progressbar";

import Portfolio from "./elements/portfolio/Portfolio";
import portfolioThreeColumn from "./elements/portfolio/PortfolioThreeColumn";
import PortfolioFullWidth from "./elements/portfolio/PortfolioFullWidth";
import PortfolioGrid from "./elements/portfolio/PortfolioGrid";
import PortfolioBoxLayout from "./elements/portfolio/PortfolioBoxLayout";
import PortfolioDetails from "./pages/PortfolioDetails";
import Social from "./elements/social/Social";
import Team from "./elements/team/Team";
import Timeline from "./elements/timeline/Timeline";
import Testimonial from "./elements/testimonial/Testimonial";
import RnAccordion from "./elements/accordion/RnAccordion";
import Tab from "./elements/tab/Tab";
import Pricing from "./elements/pricing/Pricing";
import Split from "./elements/split/Split";
import CalltoAction from "./elements/calltoaction/CalltoAction";
import Video from "./elements/video/Video";
import Gallery from "./elements/gallery/Gallery";
import Contact from "./elements/contact/Contact";
import Brand from "./elements/brand/Brand";
import AdvanceTab from "./elements/advancetab/AdvanceTab";
import AdvancePricing from "./elements/advancePricing/AdvancePricing";


import BlogGridView from "./components/blog/BlogGridView";
import BlogListView from "./components/blog/BlogListView";
import BlogGridSidebar from "./components/blog/BlogGridSidebar";
import BlogListSidebar from "./components/blog/BlogListSidebar";
import BlogDetails from "./pages/BlogDetails";
import Error from "./pages/Error";


// Import Css Here 
import './assets/scss/style.scss';
import Blogs from './pages/Blogs';
import ConferenceFormationsBlockchain from './pages/ConferenceFormationsBlockchain';
import ConseilsDeveloppement from './pages/ConseilsDeveloppement';
import CryptoActifs from './pages/CryptoActifs';
import AdministrationPublique from './pages/casApplicationBlockchain/AdministrationPublique';
import AgroIndustrieAgriculture from './pages/casApplicationBlockchain/AgroIndustrieAgriculture';
import Assurance from './pages/casApplicationBlockchain/Assurance';
import BanqueFinance from './pages/casApplicationBlockchain/BanqueFinance';
import Culture from './pages/casApplicationBlockchain/Culture';
import Education from './pages/casApplicationBlockchain/Education';
import Hotellerie from './pages/casApplicationBlockchain/Hotellerie';
import MediaPublicite from './pages/casApplicationBlockchain/MediaPublicite';
import Sante from './pages/casApplicationBlockchain/Sante';
import SupplyChain from './pages/casApplicationBlockchain/SupplyChain';

const App = () => {
    return (
        <Router>
            <PageScrollTop>
                <Routes>
                    {/* <Route path={`${process.env.PUBLIC_URL + "/"}`} element={<Splash />} /> */}
                    <Route path="/" element={<Accueil />} />
                    <Route path="/nos-missions" element={<NosMissions />} />
                    <Route path="/nos-offres" element={<NosOffres />} />
                    <Route path="/details-services/:id" element={<DetailsServices />} />
                    <Route path="/nos-projets" element={<NosProjets />} />
                    <Route path="/blockchain" element={<Blockchain />} />
                    <Route path="/conference-formations-blockchain" element={<ConferenceFormationsBlockchain />} />
                    <Route path="/conseils-developpement" element={<ConseilsDeveloppement />} />
                    <Route path="/crypto-actifs" element={<CryptoActifs/>} />
                    <Route path="/lexique" element={<Lexique />} />
                    
                    Routes des cas d'application
                    <Route path="/cas-application" element={<CasApplication />} />
                    <Route path="/cas-application-blockchain/sport" element={<Sport />} />
                    <Route path="/cas-application-blockchain/administration-publique" element={<AdministrationPublique />} />
                    <Route path="/cas-application-blockchain/agro-industrie-agriculture" element={<AgroIndustrieAgriculture />} />
                    <Route path="/cas-application-blockchain/assurance" element={<Assurance />} />
                    <Route path="/cas-application-blockchain/banque-finance" element={<BanqueFinance />} />
                    <Route path="/cas-application-blockchain/culture" element={<Culture />} />
                    <Route path="/cas-application-blockchain/education" element={<Education />} />
                    <Route path="/cas-application-blockchain/hotellerie" element={<Hotellerie />} />
                    <Route path="/cas-application-blockchain/media-publicite" element={<MediaPublicite />} />
                    <Route path="/cas-application-blockchain/sante" element={<Sante />} />
                    <Route path="/cas-application-blockchain/supply-chain" element={<SupplyChain />} />
                    
                    {/* Routes des blogs */}
                    <Route path="/blogs" element={<Blogs/>} />

                    {/* Routes d'authentification */}
                    <Route path="/authentification" element={<Authentification />} />

                    {/* Route pour magic */}
                    <Route path="/callback" element={<Callback />} />



                    {/* Routes du profil */}
                    <Route path="/profil/dashboard" element={<Dashboard />} />
                    <Route path="/profil" element={<ProfileIndex />} />
                    <Route path="/profil/profil-dashboard" element={<ProfilDashboard />} />
                    <Route path="/profil/profil-infos-user" element={<ProfilInfosUser />} />

                    

                    <Route path="/business-consulting" element={<HomeDefault />} />
                    <Route path="/business-consulting-2" element={<BusinessConsulting2 />} />
                    <Route path="/corporate" element={<Corporate />} />
                    <Route path="/business" element={<Business />} />
                    <Route path="/digital-agency" element={<DigitalAgency />} />
                    <Route path="/finance" element={<Finance />} />
                    <Route path="/company" element={<Company />} />
                    <Route path="/marketing" element={<Marketing />} />
                    <Route path="/travel-agency" element={<TravelAgency />} />
                    <Route path="/consulting" element={<Consulting />} />
                    <Route path="/seo-agency" element={<SeoAgency />} />
                    <Route path="/personal-portfolio" element={<PersonalPortfolio />} />
                    <Route path="/event-conference" element={<EventConference />} />
                    <Route path="/creative-portfolio" element={<CreativePortfolio />} />
                    <Route path="/freelancer" element={<Freelancer />} />
                    <Route path="/international-consulting" element={<InternationalConsulting />} />
                    <Route path="/startup" element={<Startup />} />
                    <Route path="/web-agency" element={<WebAgency />} />

                    {/* Blog Part */}
                    <Route path="/blog-grid" element={<BlogGridView />} />
                    <Route path="/blog-list-view" element={<BlogListView />} />
                    <Route path="/blog-grid-sidebar" element={<BlogGridSidebar />} />
                    <Route path="/blog-list-sidebar" element={<BlogListSidebar />} />
                    <Route path="/blog-details/:id" element={<BlogDetails />} />
                    <Route path="/category/:slug" element={<CategoryList />} />
                    <Route path="/archive/:slug" element={<Author />} />
                    <Route path="/tag/:slug" element={<TagList />} />

                    {/* Elements */}
                    <Route path="/elements" element={<Elements />} />
                    <Route path="/button" element={<Button />} />
                    <Route path="/service" element={<Service />} />
                    <Route path="/counter" element={<CounterUp />} />
                    <Route path="/progressbar" element={<Progressbar />} />
                    <Route path="/social-share" element={<Social />} />
                    <Route path="/team" element={<Team />} />
                    <Route path="/testimonial" element={<Testimonial />} />
                    <Route path="/timeline" element={<Timeline />} />
                    <Route path="/accordion" element={<RnAccordion />} />
                    <Route path="/tab" element={<Tab />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/split" element={<Split />} />
                    <Route path="/call-to-action" element={<CalltoAction />} />
                    <Route path="/video-popup" element={<Video />} />
                    <Route path="/gallery" element={<Gallery />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/brand" element={<Brand />} />
                    <Route path="/advance-tab" element={<AdvanceTab />} />
                    <Route path="/advance-pricing" element={<AdvancePricing />} />
                    <Route path="/about-us" element={<AboutUs />} />

                    <Route path="/error" element={<Error />} />
                    
                    <Route path="/portfolio" element={<Portfolio />} />
                    <Route path="/portfolio-three-column" element={<portfolioThreeColumn />} />
                    <Route path="/portfolio-full-width" element={<PortfolioFullWidth />} />
                    <Route path="/portfolio-grid-layout" element={<PortfolioGrid />} />
                    <Route path="/portfolio-box-layout" element={<PortfolioBoxLayout />} />
                    <Route path="/portfolio-details/:id" element={<PortfolioDetails />} />
                </Routes>
            </PageScrollTop>
        </Router>
    )
}


export default App
