import React, { useEffect, useRef } from 'react';
import {Typed} from 'react-typed';
import ScrollAnimation from 'react-animate-on-scroll';
import SEO from "../../common/SEO";
import HeaderTwo from '../../common/header/HeaderTwo';
import {Link} from "react-router-dom";
import FooterTwo from '../../common/footer/FooterTwo';
import Separator from "../../elements/separator/Separator";
import CasSport from '../../components/wti/blockchain/casApplication/CasSport';
import Copyright from '../../common/footer/Copyright';





/**
 * Page détaillant l'application de la blockchain dans le secteur du sport.
 *
 * Ce composant présente comment Wealthtech Innovations exploite la blockchain pour apporter des innovations
 * significatives dans le domaine du sport, soulignant les avantages de cette technologie pour améliorer
 * l'authenticité des produits, la billetterie sécurisée, et l'engagement des fans. Les visiteurs peuvent découvrir
 * des études de cas et des projets développés spécifiquement pour ce secteur.
 *
 * @returns {JSX.Element} Le composant React pour la page dédiée à l'application blockchain dans le sport.
 */
const Sport = () => {
    // Variable de l'url du frontend
    const API_URL_FRONTEND =process.env.REACT_APP_API_URL_FRONTEND;
    const typedTarget = useRef(null);


    /**
     * useEffect qui fait défiler les mots
     *
     * @type {Object[]}
     */
    useEffect(() => {
        const options = {
            
            strings: ["Billetterie sécurisée", "Merchandising innovant", "Intégrité des paris", "Automatisation des droits", "Défis de réglementation"],
            typeSpeed: 80,
            backSpeed: 5,
            backDelay: 1000,
            loop: true,
        };

        const typed = new Typed(typedTarget.current, options);

        return () => {
            typed.destroy();
        };
    }, []);

    
    return (
        <>
            <SEO
                title="Blockchain dans le Sport - Innovations par Wealthtech Innovations"
                description="Découvrez comment la blockchain transforme le secteur sportif avec Wealthtech Innovations. Explorez nos cas d'application pour l'authentification des produits, la billetterie et l'engagement des fans grâce à des solutions innovantes."
                canonicalUrl={`https://www.wealthtechinnovations.com/cas-application-blockchain/sport`}
                ogTitle="Révolutionnez le Secteur Sportif avec la Blockchain - Wealthtech Innovations"
                ogDescription="Wealthtech Innovations utilise la blockchain pour réinventer l'industrie du sport, offrant des solutions pour l'authenticité des marchandises, la gestion de billetterie sécurisée, et une nouvelle ère d'engagement des supporters."
                ogUrl={`https://www.wealthtechinnovations.com/cas-application-blockchain/sport`}
            />

            <main className="page-wrapper">
                <HeaderTwo btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 variation-default height-450 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/wti/new/sports/sportBg-1.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner ">
                                    <h2 className="text-center display-one theme-gradient color-white">Sport<br/>
                                    <span ref={typedTarget}></span>
                                    </h2>
                                    
                                    <p className="descriptionCasApp">
                                        La blockchain transforme le secteur sportif en offrant des solutions innovantes pour la gestion des fan-clubs, la billetterie, le merchandising, et les paris sportifs. Cette technologie garantit la sécurité, la transparence et l'efficacité, en créant un environnement de confiance pour les fans, les équipes et les organisateurs d'événements. Avec la blockchain, les billets deviennent inviolables et leur revente est contrôlée, éliminant ainsi le marché noir et garantissant l'authenticité des billets. De plus, elle permet aux fans d'acquérir des articles de collection numériques, comme des NFTs, offrant une nouvelle source de revenus pour les équipes et une nouvelle forme d'engagement pour les supporters. Dans le domaine des paris sportifs, la blockchain assure des transactions transparentes et sécurisées, améliorant l'intégrité du secteur. Les contrats intelligents peuvent également être utilisés pour automatiser les paiements des droits de diffusion et des sponsorisations, simplifiant les accords commerciaux. Cependant, l'adoption de la blockchain dans le sport pose des défis liés à la réglementation, à l'acceptation par les fans et à l'intégration avec les systèmes existants. Une collaboration étroite entre les acteurs du secteur, les développeurs de blockchain et les régulateurs est essentielle pour exploiter pleinement son potentiel.
                                    </p>
                                    <div className="button-group text-center mb-3">
                                        <Link className="btn-default btn-medium round btn-icon" to="/cas-application">Cas application</Link>
                                        {/* <Link className="btn-default btn-medium btn-border round btn-icon" to="/contact">Contactez-nous <i className="icon"></i></Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />
                {/* <AboutTwo data={data[0]}/> */}

                {/* contenu */}
                <CasSport/>
                {/* FIN */}
                
                <Separator />
                

                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default Sport
