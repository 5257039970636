import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FiArrowRight, FiCheck } from "react-icons/fi";

// Pour le Carousel slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Carousel } from 'bootstrap';

/**
 * Liste des image qui defillent.
 *
 * @type {Object[]}
 */
 const list = [
    {
        id: 1,
        image: '/images/wti/new/hotellerie/hotellerie-1.jpg',
    },
    {
        id: 2,
        image: '/images/wti/new/hotellerie/hotellerie-2.jpg',
    },
    {
        id: 3,
        image: '/images/wti/new/hotellerie/hotellerie-3.jpg',
    },
    {
        id: 4,
        image: '/images/wti/new/hotellerie/hotellerie-4.jpg',
    },
    {
        id: 5,
        image: '/images/wti/new/hotellerie/hotellerie-5.jpg',
    },
 ]

const CasHotellerie = () => {
    // Paramètres pour le carrousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: "linear"
    };
    return (
        <div className="rwt-about-area about-style-2 ">
            <div className="container">
                <div className="row row--30 align-items-center mt-5">
                    <div className="col-lg-5">
                    <Slider {...settings}>
                            {list?.map((item, index) => (
                        <div key={index} className="thumbnail">
                            <img className="w-100" src={item.image} alt={`Additional ${index}`}/>
                        </div>
                            ))}
                    </Slider>

                    </div>

                    
                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                <ul className="list-iconCasApp">
                                    <li><span className="icon"><FiCheck /></span><b>Réservations simplifiées :</b> La blockchain supprime les intermédiaires, facilitant des réservations directes, plus rapides et économiques, améliorant ainsi l'expérience client.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Transactions sécurisées :</b> Les contrats intelligents offrent une sécurité et une transparence accrues pour les réservations et paiements, renforçant la confiance des utilisateurs.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Gestion de la fidélité :</b> Un système de fidélité sur blockchain augmente la transparence et simplifie la gestion des récompenses, fidélisant davantage les clients.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Protection des données clients :</b> La blockchain assure une gestion sécurisée et transparente des données personnelles des clients, renforçant leur confiance envers l'hôtel.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Défis d'implémentation :</b> L'adoption de la blockchain dans l'hôtellerie implique des investissements en formation et en technologie, mais promet une amélioration significative de l'efficacité.</li>
                                </ul>


 
 

                                
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default CasHotellerie;
