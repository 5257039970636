import React, { useEffect, useRef } from 'react';
import {Typed} from 'react-typed';
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import HeaderTwo from '../common/header/HeaderTwo';
import Copyright from '../common/footer/Copyright';
import Separator from "../elements/separator/Separator";
import BlockchainApplication from '../components/wti/blockchain/BlockchainApplication';

/**
 * Le composant `CasApplication` sert de page principale pour présenter divers cas d'utilisation de la technologie blockchain.
 * Il met en avant les différents secteurs pouvant bénéficier de la blockchain à travers une animation de texte
 * et propose des liens vers des ressources supplémentaires ou des définitions liées à la blockchain.
 * 
 * Ce composant utilise plusieurs composants et bibliothèques externes pour structurer la page et fournir
 * des fonctionnalités spécifiques :
 * - `react-typed` pour créer une animation de texte énumérant différents secteurs d'application de la blockchain.
 * - `SEO` pour configurer les métadonnées de la page pour une meilleure optimisation pour les moteurs de recherche.
 * - `Link` de `react-router-dom` pour la navigation sans rechargement de page.
 * - Plusieurs composants personnalisés (`HeaderTwo`, `Copyright`, `Separator`, `BlockchainApplication`) pour
 *   construire le layout de la page.
 *
 * @returns {React.Component} Un composant React qui affiche la page des cas d'application de la blockchain, comprenant
 *                            une introduction animée, des liens vers d'autres sections du site, et une présentation
 *                            détaillée de l'utilisation de la blockchain dans différents secteurs.
 * 
 * Exemple d'utilisation :
 * Pas de props spécifiques à passer. Utilisez simplement le composant dans votre application pour afficher la page :
 * ```jsx
 * <CasApplication />
 * ```
 */
const CasApplication = () => {
    // Variable de l'url du frontend
    const API_URL_FRONTEND =process.env.REACT_APP_API_URL_FRONTEND;
    const typedTarget = useRef(null);

    useEffect(() => {
        const options = {
            strings: ["Sport","Culture","Santé","Administration","Agriculture","Education","Hôtellerie","Assurance","Média","Finance","Supply Chain"],
            typeSpeed: 80,
            backSpeed: 5,
            backDelay: 1000,
            loop: true,
        };

        const typed = new Typed(typedTarget.current, options);

        return () => {
            typed.destroy();
        };
    }, []);

    return (
        <>
            <SEO
                title="Cas d'Application de la Blockchain dans Divers Secteurs - Wealthtech Innovations"
                description="Découvrez comment la blockchain transforme les secteurs du Sport, de la Culture, de la Santé et plus encore avec Wealthtech Innovations. Explorez les applications révolutionnaires et comprenez l'impact de cette technologie sur diverses industries."
                canonicalUrl={`https://www.wealthtechinnovations.com/cas-application`}
                ogTitle="Révolutionnez Votre Secteur avec la Blockchain - Wealthtech Innovations"
                ogDescription="Avec Wealthtech Innovations, explorez des cas d'application de la blockchain qui réinventent le Sport, la Culture, la Santé, et bien d'autres secteurs. Découvrez le potentiel transformateur de cette technologie."
                ogUrl={`https://www.wealthtechinnovations.com/cas-application`}
            />

            <main className="page-wrapper">
                <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" />

                 {/* Start Slider Area  */}

                <div className="slider-area slider-style-1 bg-transparent py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <h1 className="title theme-gradient display-two">Cas d'Application Blockchain: <br />
                                        <span ref={typedTarget}></span>
                                    </h1>
                                    <p className="description">La blockchain trouve des applications variées, de la finance décentralisée à la traçabilité.</p>
                                    <div className="button-group">
                                        {/* <a className="btn-default btn-medium round btn-icon" target="_blank" href="https://themeforest.net/checkout/from_item/33571911?license=regular">Vers nos missions<i className="icon"><FiArrowRight /></i></a> */}
                                        <Link className="btn-default btn-medium round btn-icon" to="/blockchain">Vers Blockchain</Link>
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="/lexique">Vers le lexique <i className="icon"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />                            

                {/* Partie blockchain cas d'application */}
                <br/><br/>
                <BlockchainApplication/>
                {/* Fin partie blockchain cas d'application */}

                {/* Start Call To Action Area  */}
                <div className="rwt-callto-action-area">
                    <div className="wrapper">
                        {/* <CalltoActionFive /> */}
                    </div>
                </div>
                {/* End Call To Action Area  */}
                

                <Separator />                               
               
                {/* <FooterTwo /> */}
                <Copyright />
            </main>
        </>
    )
}

export default CasApplication;