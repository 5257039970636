import React, { useEffect, useRef } from 'react';
import {Typed} from 'react-typed';
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import HeaderTwo from '../common/header/HeaderTwo';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import Separator from "../elements/separator/Separator";
import BlogClassicData from '../data/blog/BlogList.json';
import BlockchainDefinition from '../components/wti/blockchain/BlockchainDefinition';
import BlockchainCaracteristique from '../components/wti/blockchain/BlockchainCaracteristique';





const Blockchain = () => {
    // Variable de l'url du frontend
    const API_URL_FRONTEND =process.env.REACT_APP_API_URL_FRONTEND;
    const typedTarget = useRef(null);

    useEffect(() => {
        const options = {
            strings: ["Définition et.", "Caractéristique."],
            typeSpeed: 80,
            backSpeed: 5,
            backDelay: 1000,
            loop: true,
        };

        const typed = new Typed(typedTarget.current, options);

        return () => {
            typed.destroy();
        };
    }, []);

    return (
        <>
            <SEO
                title="Blockchain || Définition et Caractéristiques - Wealthtech Innovations"
                description="Explorez la technologie Blockchain avec Wealthtech Innovations: découvrez sa définition, ses caractéristiques uniques, et comment elle révolutionne la confiance et la sécurité en ligne. Plongez dans le futur de la finance et de la technologie."
                canonicalUrl={`https://www.wealthtechinnovations.com/blockchain`}
                ogTitle="Blockchain Expliquée par Wealthtech Innovations: Définition et Caractéristiques"
                ogDescription="Wealthtech Innovations vous guide à travers la technologie Blockchain, dévoilant sa capacité à transformer la confiance et la sécurité sur Internet. Découvrez avec nous les applications révolutionnaires de la blockchain."
                ogUrl={`https://www.wealthtechinnovations.com/blockchain`}
            />
            <main className="page-wrapper">
                <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" />

                 {/* Start Slider Area  */}

                <div className="slider-area slider-style-1 bg-transparent py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <h1 className="title theme-gradient display-two">Technologie Blockchain <br />
                                        <span ref={typedTarget}></span>
                                    </h1>
                                    <p className="description">La blockchain révolutionne la confiance en ligne avec sa sécurité et transparence des transactions.</p>
                                    <div className="button-group">
                                        {/* <a className="btn-default btn-medium round btn-icon" target="_blank" href="https://themeforest.net/checkout/from_item/33571911?license=regular">Vers nos missions<i className="icon"><FiArrowRight /></i></a> */}
                                        <Link className="btn-default btn-medium round btn-icon" to="/cas-application">Cas d'application</Link>
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="/lexique">Vers le lexique <i className="icon"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />                            

                {/* Definition de la blockchain */}
                <br/><br/>
                <BlockchainDefinition/>
                {/* Fin Definition de la blockchain */}
                <Separator />                            

                {/* Definition de la blockchain */}
                <br/><br/>
                <BlockchainCaracteristique/>
                {/* Fin Definition de la blockchain */}

                {/* Start Call To Action Area  */}
                <div className="rwt-callto-action-area">
                    <div className="wrapper">
                        {/* <CalltoActionFive /> */}
                    </div>
                </div>
                {/* End Call To Action Area  */}
                

                <Separator />                               
               
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default Blockchain;