import React, { useEffect, useRef } from 'react';
import {Typed} from 'react-typed';
import ScrollAnimation from 'react-animate-on-scroll';
import SEO from "../../common/SEO";
import HeaderTwo from '../../common/header/HeaderTwo';
import {Link} from "react-router-dom";
import FooterTwo from '../../common/footer/FooterTwo';
import Separator from "../../elements/separator/Separator";
import Copyright from '../../common/footer/Copyright';
import CasEducation from '../../components/wti/blockchain/casApplication/CasEducation';





/**
 * Page détaillant l'application de la blockchain dans le secteur de l'éducation.
 *
 * Ce composant présente comment Wealthtech Innovations utilise la blockchain pour innover
 * dans le domaine de l'éducation, soulignant les avantages de cette technologie pour améliorer
 * la sécurité des diplômes, faciliter les processus d'apprentissage et promouvoir l'accès universel
 * à l'éducation. Les visiteurs peuvent découvrir des exemples concrets et des projets développés
 * spécifiquement pour ce secteur.
 *
 * @returns {JSX.Element} Le composant React pour la page dédiée à l'application blockchain dans l'éducation.
 */
const Education = () => {
    // Variable de l'url du frontend
    const API_URL_FRONTEND =process.env.REACT_APP_API_URL_FRONTEND;
    const typedTarget = useRef(null);


    /**
     * useEffect qui fait défiler les mots
     *
     * @type {Object[]}
     */
    useEffect(() => {
        const options = {
            
            strings: ["Sécurisée des dossiers", "Automatisation", "Reconnaissance", "Apprentissage personnalisé", "Défis d'intégration"],
            typeSpeed: 80,
            backSpeed: 5,
            backDelay: 1000,
            loop: true,
        };

        const typed = new Typed(typedTarget.current, options);

        return () => {
            typed.destroy();
        };
    }, []);

    
    return (
        <>
            <SEO
                title="Blockchain dans l'Éducation - Avancées avec Wealthtech Innovations"
                description="Découvrez comment la blockchain révolutionne l'éducation avec Wealthtech Innovations. Explorez nos cas d'application pour sécuriser les diplômes, faciliter l'apprentissage et promouvoir l'accès universel à l'éducation."
                canonicalUrl={`https://www.wealthtechinnovations.com/cas-application-blockchain/education`}
                ogTitle="Transformez l'Éducation avec la Blockchain - Wealthtech Innovations"
                ogDescription="Wealthtech Innovations explore le potentiel de la blockchain pour améliorer la sécurité, l'accessibilité et l'efficacité de l'éducation. Découvrez nos solutions innovantes pour un futur éducatif plus brillant."
                ogUrl={`https://www.wealthtechinnovations.com/cas-application-blockchain/education`}
            />

            <main className="page-wrapper">
                <HeaderTwo btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 variation-default height-450 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/wti/new/education/educationBg-1.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner ">
                                    <h2 className="text-center display-one theme-gradient color-white"> Education<br/>
                                    <span ref={typedTarget}></span>
                                    </h2>
                                    
                                    <p className="descriptionCasApp">
                                        L'introduction de la blockchain dans l'éducation promet de révolutionner la gestion et la sécurisation des dossiers éducatifs. Cette technologie offre une plateforme décentralisée et inaltérable pour l'enregistrement et le partage des crédits et des qualifications académiques, facilitant ainsi la mobilité étudiante et la reconnaissance des diplômes à l'international. En outre, la blockchain peut automatiser la vérification des qualifications, réduisant le temps et les coûts associés à ces procédures. Elle favorise également le développement de modèles d'apprentissage personnalisés et sécurisés, en permettant aux étudiants de contrôler l'accès à leurs données personnelles et académiques. Cette technologie ouvre la voie à une nouvelle ère de transparence et d'efficacité dans l'éducation, mais son intégration requiert une adaptation des infrastructures existantes et une sensibilisation à ses avantages. Les défis comprennent également les questions de confidentialité, la nécessité de standards universels pour les dossiers éducatifs, et la formation des personnels à l'utilisation de cette nouvelle technologie.
                                    </p>
                                    <div className="button-group text-center mb-3">
                                        <Link className="btn-default btn-medium round btn-icon" to="/cas-application">Cas application</Link>
                                        {/* <Link className="btn-default btn-medium btn-border round btn-icon" to="/contact">Contactez-nous <i className="icon"></i></Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />
                {/* <AboutTwo data={data[0]}/> */}

                {/* contenu */}
                <CasEducation/>
                {/* FIN */}
                
                <Separator />
                

                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default Education
