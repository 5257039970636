import React, { useEffect, useRef } from 'react';
import {Typed} from 'react-typed';
import ScrollAnimation from 'react-animate-on-scroll';
import SEO from "../../common/SEO";
import HeaderTwo from '../../common/header/HeaderTwo';
import {Link} from "react-router-dom";
import FooterTwo from '../../common/footer/FooterTwo';
import Separator from "../../elements/separator/Separator";
import Copyright from '../../common/footer/Copyright';
import CasMediaPublicite from '../../components/wti/blockchain/casApplication/CasMediaPublicite';





/**
 * Page détaillant l'application de la blockchain dans les secteurs des médias et de la publicité.
 *
 * Ce composant présente comment Wealthtech Innovations utilise la blockchain pour apporter des innovations
 * significatives dans les médias et la publicité, en mettant en avant les avantages de cette technologie pour
 * améliorer la transparence, sécuriser les droits d'auteur et optimiser l'attribution des publicités.
 * Les visiteurs peuvent découvrir des études de cas et des projets développés spécifiquement pour ces industries.
 *
 * @returns {JSX.Element} Le composant React pour la page dédiée à l'application blockchain dans les médias et la publicité.
 */
const MediaPublicite = () => {
    // Variable de l'url du frontend
    const API_URL_FRONTEND =process.env.REACT_APP_API_URL_FRONTEND;
    const typedTarget = useRef(null);


    /**
     * useEffect qui fait défiler les mots
     *
     * @type {Object[]}
     */
    useEffect(() => {
        const options = {
            
            strings: ["Transparence des dépenses", "Automatisation des droits", "Protection", "Publicité ciblée", "Défis d'adoption"],
            typeSpeed: 80,
            backSpeed: 5,
            backDelay: 1000,
            loop: true,
        };

        const typed = new Typed(typedTarget.current, options);

        return () => {
            typed.destroy();
        };
    }, []);

    
    return (
        <>
            <SEO
                title="Blockchain dans les Médias et la Publicité - Avancées avec Wealthtech Innovations"
                description="Découvrez comment la blockchain révolutionne les secteurs des médias et de la publicité avec Wealthtech Innovations. Explorez nos cas d'application pour une transparence accrue, une meilleure attribution des publicités et la sécurisation des droits d'auteur."
                canonicalUrl={`https://www.wealthtechinnovations.com/cas-application-blockchain/media-publicite`}
                ogTitle="Réinventez les Médias et la Publicité avec la Blockchain - Wealthtech Innovations"
                ogDescription="Wealthtech Innovations explore le potentiel de la blockchain pour transformer les industries des médias et de la publicité. Découvrez comment nos solutions promettent une révolution dans la gestion des contenus et la publicité."
                ogUrl={`https://www.wealthtechinnovations.com/cas-application-blockchain/media-publicite`}
            />

            <main className="page-wrapper">
                <HeaderTwo btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 variation-default height-450 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/wti/new/media/mediaBg-1.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner ">
                                    <h2 className="text-center display-one theme-gradient color-white"> Média et publicité<br/>
                                    <span ref={typedTarget}></span>
                                    </h2>
                                    
                                    <p className="descriptionCasApp">
                                        L'adoption de la blockchain dans les médias et la publicité offre une révolution potentielle en termes de transparence, d'efficacité et de confiance. Dans un secteur souvent critiqué pour son manque de transparence dans les dépenses publicitaires et la distribution des contenus, la blockchain promet de rétablir la confiance en authentifiant et en enregistrant chaque transaction et interaction. Cela permet une traçabilité complète des dépenses publicitaires, réduisant les coûts liés à la fraude publicitaire et assurant une répartition équitable des revenus entre les créateurs de contenu et les plateformes de diffusion. En outre, grâce à des contrats intelligents, la blockchain peut automatiser le paiement des droits d'auteur, simplifier la gestion des licences de contenu et renforcer la protection contre le piratage. Elle ouvre également la voie à des modèles publicitaires plus directs et personnalisés, en permettant aux annonceurs de cibler et de récompenser directement leur audience. Toutefois, la mise en œuvre de la blockchain dans ce secteur est confrontée à des défis, notamment en matière de scalabilité, d'interopérabilité entre les différentes blockchains et d'adoption par les principaux acteurs du marché. Pour que son potentiel soit pleinement réalisé, une collaboration étroite entre les créateurs de contenu, les annonceurs, les plateformes de diffusion et les technologues est essentielle.
                                    </p>
                                    <div className="button-group text-center mb-3">
                                        <Link className="btn-default btn-medium round btn-icon" to="/cas-application">Cas application</Link>
                                        {/* <Link className="btn-default btn-medium btn-border round btn-icon" to="/contact">Contactez-nous <i className="icon"></i></Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />
                {/* <AboutTwo data={data[0]}/> */}

                {/* contenu */}
                <CasMediaPublicite/>
                {/* FIN */}
                
                <Separator />
                

                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default MediaPublicite
