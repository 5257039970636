import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../breadcrumb/BreadcrumbOne";
import SectionTitle from "../sectionTitle/SectionTitle";
import ContactOne from "./ContactOne";
/**
 * Page de contact pour Wealthtech Innovations.
 *
 * Ce composant affiche la page de contact, y compris un formulaire pour que les visiteurs puissent envoyer
 * des messages directement à l'équipe de Wealthtech Innovations. La page inclut des métadonnées SEO optimisées
 * pour améliorer sa découverte via les moteurs de recherche et encourage l'engagement utilisateur en fournissant
 * une méthode facile et directe de communication.
 *
 * @component
 * @example
 * return (
 *   <Contact />
 * )
 */
const Contact = () => {
    // Variable de l'url du frontend
    const API_URL_FRONTEND =process.env.REACT_APP_API_URL_FRONTEND;
    return (
        <>
            <SEO 
                title="Contactez Wealthtech Innovations - Nous sommes là pour vous aider"
                description="Vous avez des questions sur la blockchain ou besoin d'assistance pour vos projets ? Contactez l'équipe de Wealthtech Innovations dès aujourd'hui."
                canonicalUrl={`https://www.wealthtechinnovations.com/contact`}
                ogTitle="Contactez l'Équipe de Wealthtech Innovations"
                ogDescription="Besoin d'informations supplémentaires ou de support sur nos services et solutions en blockchain ? L'équipe Wealthtech Innovations est prête à vous assister."
                ogUrl={`https://www.wealthtechinnovations.com/contact`}
            />
            <Layout>
                <BreadcrumbOne 
                    title="Contactez nous "
                    rootUrl="/"
                    parentUrl="Accueil"
                    currentUrl="Contact"
                />
                <div className="main-content">
                    {/* Start Contact Area  */}
                    <ContactOne />

                    {/* <div className="rwt-contact-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Formulaire de contact"
                                        title = "Notre adresse de contact."
                                        description = ""
                                    />
                                </div>
                            </div>
                            <ContactOne />
                        </div>
                    </div> */}
                    {/* End Contact Area  */}
                </div>
            </Layout>
        </>
    )
}
export default Contact;