import { getDefaultNormalizer } from "@testing-library/react";
import React, { useState, useEffect } from "react";
import { FiCopy } from "react-icons/fi";
// import './UserProfile.css'; // Assurez-vous d'inclure le chemin correct vers votre fichier CSS

const ProfileInfosUser = () => {
    // Variable de l'url de l'api
    const API_URL =process.env.REACT_APP_API_URL;
    const API_KEY_WEALTHTECH =process.env.REACT_APP_API_KEY_WEALTHTECH;

    const [currentUser, setCurrentUser]=useState()

    // Obtenir l'utilisateur connecté 
    
    useEffect(() => {
        const getUser = async () => {
            const token = localStorage.getItem('tokenEnCours');

            try {
                const result = await fetch(`${API_URL}/api/user/find-user-sign-in`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                        'x-api-key': `${API_KEY_WEALTHTECH}`,

                    },
                });

                if (!result.ok) {
                    throw new Error('Failed to fetch user data');
                }

                const data = await result.json();
                setCurrentUser(data);

            } catch (error) {
                console.error('Erreur lors de la récupération de l\'utilisateur:', error);
            }
        };

        getUser();
    }, [API_URL]); // Ajoutez API_URL comme dépendance si elle est susceptible de changer

    // Fin

    const [userProfile] = useState({
        photoUrl: '/images/wti/profil/avatar.png', // Remplacez par le chemin vers la photo de profil
        name: 'Zié Arouna Koné',
        age: 30,
        email:"arouna@gmail.com",
        city: "Abidjan"
    });

   
    return (
        <div className="user-profile-container" style={{ backgroundColor: 'black', color: 'white', padding: '20px' }}>
            <div className="profile-header">
                <img src={userProfile.photoUrl} alt="Profile" className="profile-photo" />
                <h4 className="">{currentUser?.email}</h4>
                {/* <h4 className="theme-gradient">{currentUser?.email}</h4> */}
                {/* <p>Âge: {userProfile.age}</p>
                <p>Ville: {userProfile.city}</p>
                <p>Email: {userProfile.email}</p> */}
            </div>
        </div>
    );
};
export default ProfileInfosUser;