import {useState, useEffect,useCallback, useRef} from "react";
import { FiMenu } from "react-icons/fi";
import Nav from './Nav';
import MobileMenu from './MobileMenu';
import Darkmode from "./Darkmode";
import useStickyHeader from "./useStickyHeader";
import Typewriter from 'typewriter-effect';
import {Link} from "react-router-dom";



const HeaderTwo = ({btnStyle, HeaderSTyle}) => {
    const [ofcanvasShow, setOffcanvasShow] = useState(false);
    const onCanvasHandler = () => {
        setOffcanvasShow(prev => !prev);
    }


    const ref = useRef();
    let [check, setCheck] = useState(true);
    const sticky = useStickyHeader( 50 );
    const headerClasses = `header-default ${(sticky && check) ? 'sticky' : ''}`
    const { clientHeight } = ref;
    
    const checkChange = (value) => {
      setCheck(value);
    };


    function TypewriterComponent() {
        return (
          <div id="animation_">
            <Typewriter
              onInit={(typewriter) => {
                typewriter
                  .typeString('Wealthtech')
                  .pauseFor(500)
                  .deleteAll()
                  .typeString('Innovations')
                  .pauseFor(500)
                  .deleteAll()
                  .start();
              }}
              options={{
                autoStart: true,
                loop: true,
              }}
            />
          </div>
        );
    }

    // FONCTION DE LA DECONNEXION
const logaout = useCallback(() => {
    try {
    magic.user.logout().then(() => {
      // supprimer le token
      localStorage.removeItem('tokenEnCours');
      // Actualisation et redirection
      setTimeout(() => {
        window.location.reload()
       }, 1000)
      Router.push("/");
  
    });
  } catch (error) {
    console.log("une erreur s'est produit =>", error)
  }
  }, []);
  // FIN
    return (
        <>
            <header ref={ref} className={`rn-header header-default header-left-align ${HeaderSTyle} ${headerClasses}`}>
                <div className="container position-relative">
                    <div className="row align-items-center">
                        <div className="col-lg-9 col-md-6 col-4 position-static">
                            <div className="header-left d-flex">
                                <p className="image-container">
                                    <TypewriterComponent /> 
                                    <img src={"/images/wti/logo/logo.png"}/>
                                </p>
                                <nav className="mainmenu-nav d-none d-lg-block">
                                    <Nav />
                                </nav>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-8">
                            <div className="header-right">
                                {/* <p className="mainmenu"> */}
                                    {/* <Link  to="/profil/dashboard">Dashboard</Link> */}
                                {/* </p> */}
                                <div className="header-btn">
                                    {/* <Link className={`btn-default ${btnStyle}`}  to="/authentification">Login</Link> */}
                                </div>
                                {/* <div className={`btn-default header-btn ${btnStyle}`} onClick={logaout}>
                                    deconnexion
                                </div> */}
                                <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                                    <div className="hamberger">
                                        
                                        <span className="hamberger-button" onClick={onCanvasHandler}><FiMenu /></span>
                                    </div>
                                </div>
                                <Darkmode />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler}  />
        </>
    )
}
export default HeaderTwo;