/**
 * Composant pour la page d'accueil.
 *
 * @component
 * @returns {JSX.Element} Composant React
 */

import React, { useEffect, useRef } from 'react';
import {Typed} from 'react-typed';
import SEO from "../common/SEO";
import {Link} from "react-router-dom";
import HeaderTwo from '../common/header/HeaderTwo';
import FooterTwo from '../common/footer/FooterTwo';
import Copyright from '../common/footer/Copyright';
import Separator from "../elements/separator/Separator";
import QuiSommesNous from '../components/wti/QuiSommesNous';



const Accueil = () => {
    // Variable de l'url du frontend
    const API_URL_FRONTEND =process.env.REACT_APP_API_URL_FRONTEND;

    const typedTarget = useRef(null);

    useEffect(() => {
        const options = {
            strings: ["Technologie.", "Blockchain."],
            typeSpeed: 80,
            backSpeed: 5,
            backDelay: 1000,
            loop: true,
        };

        const typed = new Typed(typedTarget.current, options);

        return () => {
            typed.destroy();
        };
    }, []);

    return (
        <>
            <SEO
                title="Accueil || Wealthtech Innovations - Pionnier de la Blockchain pour la promotion de l'Économie Numérique en Afrique et en Côte d'Ivoire"
                description="Wealthtech Innovations est à l'avant-garde du développement de l'économie numérique et de la blockchain en Côte d'Ivoire et en Afrique. Wealthtech innovations implémente des solutions innovantes en utilisant la blockchain et les contrats intelligents pour accompagner les entreprises et les institutions. Découvrez comment nous pouvons transformer votre organisation et saisir ensemble les opportunités offertes par la blockchain."
                canonicalUrl={`https://www.wealthtechinnovations.com/`}
                ogTitle="Rejoignez Wealthtech Innovations - Avant-Garde de la Blockchain et de l'Économie Numérique"
                ogDescription="Chez Wealthtech Innovations, nous façonnons l'avenir de l'économie numérique à travers des solutions basées sur la blockchain et les cryptoactifs, offrant aux entreprises les clés pour comprendre et exploiter les nouvelles opportunités."
                ogUrl={`https://www.wealthtechinnovations.com/`}
            />

            <main className="page-wrapper">
                <HeaderTwo btnStyle="round" HeaderSTyle="header-not-transparent" />

                 {/* Start Slider Area  */}

                <div className="slider-area slider-style-1 bg-transparent py-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-center">
                                    <span className="subtitle">L'UNIVERS DU NUMERIQUE AVEC LA BLOCKCHAIN</span>
                                    <h1 className="title theme-gradient display-two">Wealthtech Innovations <br />
                                        <span ref={typedTarget}></span>
                                    </h1>
                                    <p className="description">Nous aidons nos clients à réussir dans le numérique, en se concentrant sur la technologie blockchain.</p>
                                    <div className="button-group">
                                        {/* <a className="btn-default btn-medium round btn-icon" target="_blank" href="https://themeforest.net/checkout/from_item/33571911?license=regular">Vers nos missions<i className="icon"><FiArrowRight /></i></a> */}
                                        <Link className="btn-default btn-medium round btn-icon" to="/nos-missions">Vers nos missions</Link>
                                        <Link className="btn-default btn-medium btn-border round btn-icon" to="/contact">Contactez-nous <i className="icon"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />                            

                {/* Partie qui sommes nous */}
                <br/><br/>
                <QuiSommesNous/>
                {/* Fin partie qui sommes nous */}

                {/* Start Call To Action Area  */}
                <div className="rwt-callto-action-area">
                    <div className="wrapper">
                        {/* <CalltoActionFive /> */}
                    </div>
                </div>
                {/* End Call To Action Area  */}
                

                <Separator />                               
               
                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default Accueil;