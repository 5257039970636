import React from 'react';
import ContactForm from "./ContactForm";
import GoogleMapStyle from "./GoogleMapStyle";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";

const ContactOne = () => {
    return (
        <>
            <div className="row row--15">
            
            <div className="row mt--40 mb--40 row--15">
                <div className="col-lg-2 col-md-2"></div>
                <div className="col-lg-8 col-md-8">
                    <ContactForm formStyle="contact-form-1" />
                </div>
                <div className="col-lg-2 col-md-2"></div>

                {/* <div className="col-lg-5 mt_md--30 mt_sm--30">
                    <GoogleMapStyle />
                </div> */}
            </div>

            </div>

            
        </>
    )
}
export default ContactOne;