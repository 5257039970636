import React, { useEffect, useRef } from 'react';
import {Typed} from 'react-typed';
import ScrollAnimation from 'react-animate-on-scroll';
import SEO from "../../common/SEO";
import HeaderTwo from '../../common/header/HeaderTwo';
import {Link} from "react-router-dom";
import FooterTwo from '../../common/footer/FooterTwo';
import Separator from "../../elements/separator/Separator";
import Copyright from '../../common/footer/Copyright';
import CasCulture from '../../components/wti/blockchain/casApplication/CasCulture';





/**
 * Page détaillant l'application de la blockchain dans le secteur culturel.
 *
 * Ce composant met en avant l'utilisation innovante de la blockchain dans le domaine de la culture
 * et des arts, illustrant comment cette technologie peut contribuer à la protection des œuvres, à leur
 * distribution équitable et à l'ouverture de nouvelles possibilités de monétisation pour les artistes
 * et les créateurs. Les visiteurs peuvent découvrir des exemples concrets et des projets développés
 * par Wealthtech Innovations dans ce secteur.
 *
 * @returns {JSX.Element} Le composant React pour la page dédiée à l'application blockchain dans la culture.
 */
const Culture = () => {
    // Variable de l'url du frontend
    const API_URL_FRONTEND =process.env.REACT_APP_API_URL_FRONTEND;
    const typedTarget = useRef(null);


    /**
     * useEffect qui fait défiler les mots
     *
     * @type {Object[]}
     */
    useEffect(() => {
        const options = {
            
            strings: ["Sécurisation des droits", "Transparence et traçabilité", "Innovation avec les NFTs", "Financement participatif", "Défis d'intégration"],
            typeSpeed: 80,
            backSpeed: 5,
            backDelay: 1000,
            loop: true,
        };

        const typed = new Typed(typedTarget.current, options);

        return () => {
            typed.destroy();
        };
    }, []);

    
    return (
        <>
            <SEO
                title="Blockchain et Culture - Révolutionner les Arts avec Wealthtech Innovations"
                description="Explorez comment la blockchain redéfinit le secteur culturel avec Wealthtech Innovations. Découvrez nos cas d'application innovants pour la protection, la distribution et la monétisation des œuvres culturelles et artistiques."
                canonicalUrl={`https://www.wealthtechinnovations.com/cas-application-blockchain/culture`}
                ogTitle="Transformez la Culture avec la Blockchain - Wealthtech Innovations"
                ogDescription="Découvrez le potentiel de la blockchain pour révolutionner le monde de la culture et des arts avec Wealthtech Innovations. Protection des droits, distribution transparente et nouvelles avenues de monétisation."
                ogUrl={`https://www.wealthtechinnovations.com/cas-application-blockchain/culture`}
            />

            <main className="page-wrapper">
                <HeaderTwo btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 variation-default height-450 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/wti/new/cultures/cultureBg-1.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner ">
                                    <h2 className="text-center display-one theme-gradient color-white"> Culture<br/>
                                    <span ref={typedTarget}></span>
                                    </h2>
                                    
                                    <p className="descriptionCasApp">
                                        L'impact de la blockchain sur le secteur culturel est profond et diversifié, offrant des possibilités révolutionnaires pour la création, la distribution, et la protection des œuvres culturelles. En garantissant l'authenticité et la propriété grâce à des registres immuables, la blockchain offre aux créateurs une manière inédite de sécuriser leurs droits et de recevoir une rémunération équitable. Elle permet une traçabilité transparente des œuvres d'art, des manuscrits, et des productions musicales, facilitant ainsi la gestion des droits d'auteur et la lutte contre la contrefaçon. Par ailleurs, la blockchain stimule l'innovation en créant de nouveaux modèles économiques tels que les tokens non fongibles (NFTs) qui révolutionnent la façon dont les œuvres culturelles sont achetées, vendues, et collectionnées. Cela ouvre également la voie à des formes de financement participatif, permettant aux communautés de soutenir directement leurs artistes préférés. Toutefois, l'intégration de la blockchain dans le domaine culturel présente des défis, notamment en termes d'acceptation par le grand public, de réglementation, et de nécessité d'une infrastructure technique adaptée.
                                    </p>
                                    <div className="button-group text-center mb-3">
                                        <Link className="btn-default btn-medium round btn-icon" to="/cas-application">Cas application</Link>
                                        {/* <Link className="btn-default btn-medium btn-border round btn-icon" to="/contact">Contactez-nous <i className="icon"></i></Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />
                {/* <AboutTwo data={data[0]}/> */}

                {/* contenu */}
                <CasCulture/>
                {/* FIN */}
                
                <Separator />
                

                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default Culture
