import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { FiArrowRight, FiCheck } from "react-icons/fi";

// Pour le Carousel slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Carousel } from 'bootstrap';

/**
 * Liste des image qui defillent.
 *
 * @type {Object[]}
 */
 const list = [
    {
        id: 1,
        image: '/images/wti/new/media/media-1.jpg',
    },
    {
        id: 2,
        image: '/images/wti/new/media/media-2.jpg',
    },
    {
        id: 3,
        image: '/images/wti/new/media/media-3.jpg',
    },
    {
        id: 4,
        image: '/images/wti/new/media/media-4.jpg',
    },
    {
        id: 5,
        image: '/images/wti/new/media/media-1.jpg',
    },
 ]

const CasMediaPublicite = () => {
    // Paramètres pour le carrousel
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: "linear"
    };
    return (
        <div className="rwt-about-area about-style-2 ">
            <div className="container">
                <div className="row row--30 align-items-center mt-5">
                    <div className="col-lg-5">
                    <Slider {...settings}>
                            {list?.map((item, index) => (
                        <div key={index} className="thumbnail">
                            <img className="w-100" src={item.image} alt={`Additional ${index}`}/>
                        </div>
                            ))}
                    </Slider>

                    </div>

                    
                    <div className="col-lg-7 mt_md--30 mt_sm--30">
                        <div className="content">
                            <div className="section-title">
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                <ul className="list-iconCasApp">
                                    <li><span className="icon"><FiCheck /></span><b>Transparence des dépenses :</b> La blockchain apporte une transparence sans précédent dans les dépenses publicitaires, réduisant la fraude et optimisant les budgets.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Automatisation des droits d'auteur :</b> Les contrats intelligents facilitent la gestion des droits d'auteur, assurant une rémunération équitable et en temps réel pour les créateurs.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Protection contre le piratage :</b> La blockchain renforce la sécurité des contenus, offrant une protection robuste contre le piratage et la contrefaçon.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Publicité ciblée et récompenses :</b> Elle permet un ciblage précis et la récompense directe des consommateurs, améliorant l'efficacité des campagnes publicitaires.</li>
                                    <li><span className="icon"><FiCheck /></span><b>Défis d'adoption :</b> Les obstacles à l'adoption de la blockchain comprennent la nécessité d'une collaboration intersectorielle et l'adaptation aux nouvelles technologies.</li>
                                </ul>



 
 

                                
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    )
}

export default CasMediaPublicite;
