import React from 'react';
import { FaSistrix } from "react-icons/fa";
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import SideCategories from '../../components/blog/sidebar/SideCategories';
import ProfilDashboard from '../../components/profil/ProfilDashboard';


const Dashboard = () => {
    return (
        <>
            <SEO title="Profil || dashboard" />
            
            <Layout>
                {/* <BreadcrumbOne 
                    title="The Easiest Way To Create Website <br /> Quick Copy & Make site."
                    rootUrl="/"
                    parentUrl="Home"
                    currentUrl="Blog Grid Sidebar"
                /> */}
                <div className="main-content">
                    {/* Start Blog Area  */}
                    <div className="rn-blog-area mt-5">
                        <div className="container">
                            {/* Sidebar */}
                            <div>
                                <SideCategories />
                            </div>

                            {/* Contenu */}
                            <div>
                                <ProfilDashboard/>
                            </div>
                            
                        </div>
                    </div>
                    {/* End Blog Area  */}
                </div>

            </Layout>
        </>
    )
}

export default Dashboard
