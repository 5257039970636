import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import {Link} from "react-router-dom";

const TimelineData = [
    {
        id: "1",
        date: "Step-1",
        title: "Conférences et formations blockchain.",
        description: "Les blockchains et les actifs numériques peuvent sembler difficiles à comprendre. C’est la raison pour laquelle nous proposons des interventions pédagogiques sur mesure, adaptées au niveau et au métier des participants. Ces interventions permettent de faire comprendre simplement les enjeux, les usages et le fonctionnement des blockchains et des actifs numériques.",
        image: "conference-1",
        url:"/conference-formations-blockchain",
        workingStep: [
            {
                stepTitle: "Éducation Blockchain",
                stepDescription: "Démystifions les blockchains et actifs numériques avec des interventions adaptées.",
            },
            {
                stepTitle: "Compréhension Simplifiée",
                stepDescription: "Expliquons simplement les enjeux des blockchains et actifs numériques.",
            },
        ]
    },
    {
     
        id: "2",
        date: "Step-2",
        title: "Conseils et développement.",
        description: "Notre équipe d’experts vous accompagne à chaque étape de vos projets blockchain.",
        image: "conseil-1",
        url:"/conseils-developpement",
        workingStep: [
            {
                stepTitle: "Accompagnement Expert en Blockchain",
                stepDescription: "Notre équipe vous guide à chaque étape de vos projets blockchain.",
            },
            {
                stepTitle: "Partenariat Proactif en Blockchain",
                stepDescription: "Collaborons à chaque phase de vos projets avec notre équipe d'experts en blockchain.",
            },
        ]
    },
    {
        id: "3",
        date: "Step-3",
        title: "Actifs numériques.",
        description: "Nous vous proposons des services et formations pour comprendre l’utilité des actifs numériques, partant de l’analyse des projets listés à la détention et l’échange des actifs numériques.",
        image: "actif-1",
        url:"/crypto-actifs",
        workingStep: [
            {
                stepTitle: "Services Numériques",
                stepDescription: "Explorez nos offres pour comprendre les actifs numériques.",
            },
            {
                stepTitle: "Compréhension Totale",
                stepDescription: "Découvrez nos services pour maîtriser les actifs numériques.",
            },
        ]
    },
]

const TimelineOne = () => {
    return (
        <div className="rn-timeline-wrapper timeline-style-one position-relative">
            <div className="timeline-line"></div>
            {/* Start Single Timeline  */}

            {TimelineData.map((data, index) =>(
                <div className="single-timeline mt--50" key={index}>
                    <div className="timeline-dot">
                        <div className="time-line-circle"></div>
                    </div>
                    <div className="single-content">
                        <div className="inner">
                            <div className="row row--30 align-items-center">
                                <div className="order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40">
                                    <div className="content">
                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <span className="date-of-timeline">{data.date}</span>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <h2 className="title">{data.title}</h2>
                                        </ScrollAnimation>

                                        <ScrollAnimation 
                                        animateIn="fadeInUp"
                                        animateOut="fadeInOut"
                                        animateOnce={true}>
                                            <p className="description">{data.description}</p>
                                        </ScrollAnimation>

                                        <div className="row row--30">
                                            {data.workingStep.map((data, index)=>(
                                                <div className="col-lg-6 col-md-6 col-12" key={index}>
                                                    <div className="working-list">
                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                            <h5 className="working-title">{data.stepTitle}</h5>
                                                        </ScrollAnimation>

                                                        <ScrollAnimation 
                                                        animateIn="fadeInUp"
                                                        animateOut="fadeInOut"
                                                        animateOnce={true}>
                                                            <p>{data.stepDescription}</p>
                                                        </ScrollAnimation>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="read-morebtn">
                                            <ScrollAnimation 
                                            animateIn="fadeInUp"
                                            animateOut="fadeInOut"
                                            animateOnce={true}>
                                                <Link to={`${data.url}`} className="btn-default btn-large round">
                                                    Voir plus de détails
                                                </Link>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-lg-2 col-lg-6">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`./images/wti/new/services/${data.image}.jpg`} alt="Corporate React Template" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
           
            {/* End Single Timeline  */}
        </div>
    )
}

export default TimelineOne;
