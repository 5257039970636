import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';

const Resultat = () => {
    return (
        <p className="message-succes">Votre message a été envoyé avec succès. Je vous contacterai bientôt.</p>
    );
};

function FormulaireContact({ formStyle }) {
    const API_URL = process.env.REACT_APP_API_URL;
    const API_KEY_WEALTHTECH =process.env.REACT_APP_API_KEY_WEALTHTECH;


    const [isSending, setIsSending] = useState(false);
    const [resultShown, setResultShown] = useState(false);
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [services, setServices] = useState([]);

    const servicesOptions = [
        { value: 'Déploiement de projet blockchain', label: "Déploiement de projet blockchain" },
        { value: 'Information', label: "Information" },
        { value: 'Formation', label: "Formation" },
        { value: 'Intervention', label: "Intervention" },
        { value: 'Partenariat', label: "Partenariat" },
        { value: 'Découvrir les projets Wealthtech', label: "Découvrir les projets Wealthtech" },
        { value: 'Cryptos actifs', label: "Cryptos actifs" },
        { value: 'Autres', label: "Autres" },
    ];

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        const newServices = checked
            ? [...services, value]
            : services.filter(type => type !== value);

        setServices(newServices);
    };

    const sendMessage = async (e) => {
        e.preventDefault();
        setIsSending(true);  // Assurez-vous que cette variable est correctement initialisée et utilisée
    
        try {
            const dataForm = {
                fullName,
                email,
                phone,
                subject,
                services,
                message
            };
    
            const response = await fetch(`${API_URL}/api/contact/send-message`, {
                method: 'POST',
                body: JSON.stringify(dataForm),
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': `${API_KEY_WEALTHTECH}`,

                },
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const contentType = response.headers.get('content-type');
            console.log("contentType=>",contentType)
            if (contentType && contentType.includes('application/json')) {
                const data = await response.json();
                if (data?.message) {
                    // Supposons que `message` dans `data` signifie une erreur ou une information importante
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        html: `<p>${data.message}</p>`,
                        showConfirmButton: false,
                        timer: 10000
                    });
                } else {
                    // Cas de succès sans message spécifique dans data
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        html: `<p>Votre message a été envoyé avec succès. Nous vous contacterons bientôt.</p>`,
                        showConfirmButton: false,
                        timer: 9000
                    });
                }
            } else {
                const text = await response.text(); // Traite les réponses non-JSON comme du texte
                console.info('text =>', text);
                
                Swal.fire({
                    position: 'center',
                    icon: 'success',
                    html: `<p>Votre message a été envoyé avec succès. Nous vous contacterons bientôt.</p>`,
                    showConfirmButton: false,
                    timer: 10000
                });
                setTimeout(() => {
                    window.location.reload();
                }, 10000);
            }
        } catch (error) {
            console.error('Erreur =>', error);
            Swal.fire({
                position: 'center',
                icon: 'error',
                html: `<p>Erreur lors de l'envoi du message : ${error.message}</p>`,
                showConfirmButton: false,
                timer: 10000
            });
        }
    
        setIsSending(false);  // Réinitialiser l'état d'envoi
    };
    

    return (
        <form className={`form-style ${formStyle}`} onSubmit={sendMessage}>
            <div className="form-group groupe-checkbox row">
                <label>Choisissez un ou plusieurs services :</label>
                {servicesOptions.map((option) => (
                    <div key={option.value} className="element-checkbox col-lg-6 col-md-6">
                        <input
                            type="checkbox"
                            id={option.value}
                            value={option.value}
                            checked={services.includes(option.value)}
                            onChange={handleCheckboxChange}
                        />
                        <label htmlFor={option.value}>{option.label}</label>
                    </div>
                ))}
            </div>

            <div className="form-group">
                <input type="text" name="fullname" placeholder="Nom et prénoms" required value={fullName} onChange={e => setFullName(e.target.value)} />
            </div>
            <div className="form-group">
                <input type="email" name="email" placeholder="Adresse Email" required value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <div className="form-group">
                <input type="text" name="phone" placeholder="Numéro de téléphone (+225 0908765448)" required value={phone} onChange={e => setPhone(e.target.value)} />
            </div>
            <div className="form-group">
                <input type="text" name="subject" placeholder="Objet" required value={subject} onChange={e => setSubject(e.target.value)} />
            </div>
            <div className="form-group">
                <textarea name="message" placeholder="Votre message" required value={message} onChange={e => setMessage(e.target.value)}></textarea>
            </div>
            <div className="form-group">
                <button className="btn-default btn-large" disabled={isSending}>Envoyer</button>
            </div>
            <div className="form-group">
                {resultShown ? <Resultat /> : null}
            </div>
        </form>
    );
}

export default FormulaireContact;
