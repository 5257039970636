import React, { useEffect, useRef } from 'react';
import {Typed} from 'react-typed';
import ScrollAnimation from 'react-animate-on-scroll';
import SEO from "../../common/SEO";
import HeaderTwo from '../../common/header/HeaderTwo';
import {Link} from "react-router-dom";
import FooterTwo from '../../common/footer/FooterTwo';
import Separator from "../../elements/separator/Separator";
import Copyright from '../../common/footer/Copyright';
import CasHotellerie from '../../components/wti/blockchain/casApplication/CasHotellerie';





/**
 * Page détaillant l'application de la blockchain dans le secteur de l'hôtellerie.
 *
 * Ce composant présente comment Wealthtech Innovations utilise la blockchain pour innover
 * dans le domaine de l'hôtellerie, en mettant en avant les avantages de cette technologie pour améliorer
 * la gestion des réservations, augmenter la fidélisation de la clientèle et renforcer la sécurité des transactions.
 * Les visiteurs peuvent découvrir des études de cas et des projets développés spécifiquement
 * pour ce secteur.
 *
 * @returns {JSX.Element} Le composant React pour la page dédiée à l'application blockchain dans l'hôtellerie.
 */
const Hotellerie = () => {
    // Variable de l'url du frontend
    const API_URL_FRONTEND =process.env.REACT_APP_API_URL_FRONTEND;
    const typedTarget = useRef(null);


    /**
     * useEffect qui fait défiler les mots
     *
     * @type {Object[]}
     */
    useEffect(() => {
        const options = {
            
            strings: ["Réservations simplifiées", "Transactions sécurisées", "Gestion de la fidélité", "Protection des données", "Défis d'implémentation"],
            typeSpeed: 80,
            backSpeed: 5,
            backDelay: 1000,
            loop: true,
        };

        const typed = new Typed(typedTarget.current, options);

        return () => {
            typed.destroy();
        };
    }, []);

    
    return (
        <>
            <SEO
                title="Blockchain dans l'Hôtellerie - Avancées avec Wealthtech Innovations"
                description="Découvrez comment la blockchain transforme le secteur de l'hôtellerie avec Wealthtech Innovations. Explorez nos cas d'application pour améliorer la gestion des réservations, la fidélisation de la clientèle et la sécurité des transactions."
                canonicalUrl={`https://www.wealthtechinnovations.com/cas-application-blockchain/hotellerie`}
                ogTitle="Réinventez l'Hôtellerie avec la Blockchain - Wealthtech Innovations"
                ogDescription="Wealthtech Innovations explore le potentiel de la blockchain pour révolutionner l'industrie hôtelière. Découvrez comment nos solutions blockchain promettent une expérience client améliorée et une efficacité opérationnelle accrue."
                ogUrl={`https://www.wealthtechinnovations.com/cas-application-blockchain/hotellerie`}
            />

            <main className="page-wrapper">
                <HeaderTwo btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 variation-default height-450 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/wti/new/hotellerie/hotellerieBg-1.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner ">
                                    <h2 className="text-center display-one theme-gradient color-white"> Hôtellerie<br/>
                                    <span ref={typedTarget}></span>
                                    </h2>
                                    
                                    <p className="descriptionCasApp">
                                        La blockchain a le potentiel de révolutionner l'industrie hôtelière en apportant plus de transparence, de sécurité et d'efficacité dans divers processus. Elle peut transformer la façon dont les réservations sont effectuées et gérées, en éliminant les intermédiaires et en réduisant les coûts pour les hôtels et les clients. Les contrats intelligents, une fonctionnalité de la blockchain, peuvent automatiser les réservations, les paiements, et même la gestion des litiges, assurant ainsi des transactions fluides et sécurisées. De plus, la blockchain peut améliorer la gestion de la fidélité et des récompenses en offrant un système plus fiable et transparent pour le suivi et l'échange des points. Elle aide également à sécuriser et à gérer de manière efficace les données des clients, renforçant la confiance et la relation entre les hôtels et leurs clients. Cependant, l'intégration de la blockchain dans l'hôtellerie présente des défis, notamment en termes de coûts d'implémentation, de formation du personnel et de réglementation. Pour que son adoption soit réussie, une collaboration étroite entre les acteurs du secteur, les développeurs de technologies et les régulateurs est essentielle.
                                    </p>
                                    <div className="button-group text-center mb-3">
                                        <Link className="btn-default btn-medium round btn-icon" to="/cas-application">Cas application</Link>
                                        {/* <Link className="btn-default btn-medium btn-border round btn-icon" to="/contact">Contactez-nous <i className="icon"></i></Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />
                {/* <AboutTwo data={data[0]}/> */}

                {/* contenu */}
                <CasHotellerie/>
                {/* FIN */}
                
                <Separator />
                

                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default Hotellerie
