import React, { useEffect, useRef } from 'react';
import {Typed} from 'react-typed';
import ScrollAnimation from 'react-animate-on-scroll';
import SEO from "../../common/SEO";
import HeaderTwo from '../../common/header/HeaderTwo';
import {Link} from "react-router-dom";
import FooterTwo from '../../common/footer/FooterTwo';
import Separator from "../../elements/separator/Separator";
import Copyright from '../../common/footer/Copyright';
import CasAssurance from '../../components/wti/blockchain/casApplication/CasAssurance';





/**
 * Page dédiée aux cas d'application de la blockchain dans le secteur de l'assurance.
 *
 * Ce composant présente comment Wealthtech Innovations utilise la blockchain pour innover
 * dans le secteur de l'assurance, en mettant en avant les avantages de cette technologie pour
 * améliorer la transparence, la sécurité et l'efficacité des processus d'assurance. Les visiteurs
 * peuvent découvrir des études de cas et des projets développés spécifiquement pour ce secteur.
 *
 * @returns {JSX.Element} Le composant React pour la page dédiée à l'application blockchain dans l'assurance.
 */
const Assurance = () => {
    // Variable de l'url du frontend
    const API_URL_FRONTEND =process.env.REACT_APP_API_URL_FRONTEND;
    const typedTarget = useRef(null);


    /**
     * useEffect qui fait défiler les mots
     *
     * @type {Object[]}
     */
    useEffect(() => {
        const options = {
            
            strings: ["Réduction de la fraude", "Processus de sinistres", "Personnalisation des polices", "Défis technologiques"],
            typeSpeed: 80,
            backSpeed: 5,
            backDelay: 1000,
            loop: true,
        };

        const typed = new Typed(typedTarget.current, options);

        return () => {
            typed.destroy();
        };
    }, []);

    
    return (
        <>
            <SEO
                title="Blockchain dans l'Assurance - Solutions Innovantes de Wealthtech Innovations"
                description="Découvrez comment la blockchain révolutionne le secteur de l'assurance avec Wealthtech Innovations. Explorez nos cas d'application pour améliorer la transparence, la sécurité et l'efficacité des processus d'assurance."
                canonicalUrl={`https://www.wealthtechinnovations.com/cas-application-blockchain/assurance`}
                ogTitle="Transformez l'Assurance avec la Blockchain - Wealthtech Innovations"
                ogDescription="Wealthtech Innovations met en avant des solutions blockchain pour transformer l'industrie de l'assurance. Apprenez plus sur nos projets innovants qui promettent plus de transparence et d'efficacité."
                ogUrl={`https://www.wealthtechinnovations.com/cas-application-blockchain/assurance`}
            />

            <main className="page-wrapper">
                <HeaderTwo btnStyle="btn-small btn-icon" HeaderSTyle="header-transparent" />

                {/* Start Slider Area  */}
                <div className="slider-area slider-style-1 variation-default height-450 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/wti/new/assurance/assuranceBg-1.jpg)`}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner ">
                                    <h2 className="text-center display-one theme-gradient color-white"> Assurance<br/>
                                    <span ref={typedTarget}></span>
                                    </h2>
                                    
                                    <p className="descriptionCasApp">
                                        La blockchain révolutionne le secteur de l'assurance en apportant transparence, efficacité et sécurité. Cette technologie de registre distribué permet de gérer les contrats d'assurance, les sinistres et les paiements de manière transparente et incontestable, réduisant ainsi les fraudes et les erreurs. Elle facilite également la personnalisation des polices d'assurance grâce à une meilleure collecte et analyse des données, tout en garantissant la protection de la vie privée des assurés. La blockchain simplifie les processus de souscription et de règlement des sinistres, rendant les transactions plus rapides et moins coûteuses pour les assureurs et les assurés. De plus, elle permet de développer de nouveaux modèles d'affaires, comme les assurances paramétriques, qui paient automatiquement en fonction de l'occurrence d'un événement prédéfini. Cependant, son adoption est freinée par des défis réglementaires, technologiques et de mise en œuvre. Pour exploiter pleinement son potentiel, le secteur doit surmonter ces obstacles par l'innovation et la collaboration.
                                    </p>
                                    <div className="button-group text-center mb-3">
                                        <Link className="btn-default btn-medium round btn-icon" to="/cas-application">Cas application</Link>
                                        {/* <Link className="btn-default btn-medium btn-border round btn-icon" to="/contact">Contactez-nous <i className="icon"></i></Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Slider Area  */}

                <Separator />
                {/* <AboutTwo data={data[0]}/> */}

                {/* contenu */}
                <CasAssurance/>
                {/* FIN */}
                
                <Separator />
                

                <FooterTwo />
                <Copyright />
            </main>
        </>
    )
}

export default Assurance
