import React from 'react';
import SEO from "../common/SEO";
import Layout from "../common/Layout";
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import AuthForm from '../components/wti/auth/AuthForm';
import BreadcrumbOne from '../elements/breadcrumb/BreadcrumbOne';

const Authentification = () => {
    return (
        <>
            <SEO title="Authentification || Wealthtech innovations" />
            <Layout>
            
                <div className="main-content">
                    {/* Start Contact Area  */}
                    <div className="rwt-contact-area mt--40">
                        <div className="container">

                            {/* Partie formulaire de connexion et d'inscription */}
                            <div className="row mt--40 mb--40 row--15">
                                <div className="col-lg-2 mt_md--30 mt_sm--30"></div>
                                <div className="col-lg-8">
                                    <AuthForm formStyle="contact-form-1" />
                                </div>
                                <div className="col-lg-2 mt_md--30 mt_sm--30"></div>
                            </div>
                            {/* Fin Partie formulaire de connexion et d'inscription */}
                            
                        </div>
                    </div>
                    {/* End Contact Area  */}
                </div>
            </Layout>
        </>
    )
}
export default Authentification;